import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import {
  acceptTermsOfService,
  authorizeGustoForAccount,
  calculatePayroll,
  cancelPayroll,
  checkPayrollReady,
  completeOnboarding,
  connectBank,
  connectGustoCompany,
  createGustoCompany,
  createGustoEmployee,
  createLinkToken,
  CreateLinkToken,
  createPayrollFlow,
  createWebhook,
  deleteGustoAuthorizationForAccount,
  disconnectGustoCompany,
  disconnectGustoUser,
  downloadCompanyFormPdf,
  downloadPayStub,
  getAcceptTermsOfServiceStatus,
  getAuthorizationStatusForAccount,
  getAvailableW2Documents,
  getBankAccounts,
  getCompanyFormPdf,
  getCompanyForms,
  getCurrentGustoAccount,
  getEmployeeDocument,
  getEmployees,
  getEmployeeW2,
  getGustoCompanies,
  GetGustoCompaniesParams,
  getGustoCompany,
  getGustoCompanyByAdmin,
  getGustoTokenHealth,
  getInaccessibleCompanies,
  getOfficers,
  getOnboardingFlow,
  getPayrollOnboardingStatus,
  getPayrolls,
  GetPayrollsParams,
  getUpcomingPayrolls,
  getWebhook,
  GustoAccountResp,
  GustoCompanyResp,
  GustoTokenHealthResp,
  isEmbeddedPayrollMigrated,
  migrateEmbeddedPayroll,
  requestChangePayroll,
  saveOfficers,
  setDisabledPayrollFeature,
  signCompanyForm,
  startCustomerOnboarding,
  submitReviewAndConfirm,
} from 'services/payroll';
import { queryClient } from 'states/reactQueryClient';
import {
  Employee,
  GustoCompanyDetail,
  Payroll,
  PayrollFlowType,
  PayrollOnboardingStatus,
  SignCompanyFormBody,
} from 'models/payroll';
import { ApiListResp, getDefaultApiListResp } from 'models/api';
import { isUndefined, omitBy } from 'lodash';
import { defaultTablePageSize } from 'constants/common';

type GustoAccount = GustoAccountResp;
export const useGustoCurrentUsers = (
  queryProps?: UseQueryOptions<GustoAccount[]>,
) => {
  const { data, ...rest } = useQuery<GustoAccount[]>(
    ['gustoCurrentAccount'],
    () => getCurrentGustoAccount(),
    queryProps,
  );
  return {
    currentGustoUsers: data ?? [],
    ...rest,
  };
};

export const useGustoTokenHealth = (
  accountId: string,
  queryProps?: UseQueryOptions<GustoTokenHealthResp>,
) => {
  const { data, ...rest } = useQuery<GustoTokenHealthResp>(
    ['gustoTokenHealth', accountId],
    () => getGustoTokenHealth(accountId),
    {
      ...queryProps,
      enabled: !!accountId,
    },
  );
  return {
    tokenStatus: data,
    ...rest,
  };
};

export type GustoCompany = GustoCompanyResp;
export const useGustoCompanies = (
  params?: GetGustoCompaniesParams,
  queryProps?: UseQueryOptions<GustoCompany[]>,
) => {
  const { data, ...rest } = useQuery<GustoCompany[]>(
    ['gustoCompanies'],
    () => getGustoCompanies(params),
    queryProps,
  );
  return {
    gustoCompanies: data,
    ...rest,
  };
};

export const useGustoCompanyByAdmin = (
  companyUUID: string | undefined,
  queryProps?: UseQueryOptions<GustoCompanyDetail>,
) => {
  const { data, ...rest } = useQuery<GustoCompanyDetail>(
    ['gustoCompanyByAdmin', companyUUID],
    () => getGustoCompanyByAdmin(companyUUID!),
    {
      ...queryProps,
      enabled: !!companyUUID,
    },
  );
  return {
    gustoCompany: data,
    ...rest,
  };
};

export const useGustoCompany = (accountId: string) => {
  const { data, ...rest } = useQuery<GustoCompanyDetail>(
    ['gustoCompany', accountId],
    () => getGustoCompany(accountId),
  );
  return {
    gustoCompany: data,
    ...rest,
  };
};

export const useCreateGustoCompany = (
  accountId: string,
  createProps: UseMutationOptions = {},
) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    () => createGustoCompany(accountId),
    {
      onSuccess: () => queryClient.invalidateQueries(['account', accountId]),
      ...createProps,
    },
  );
  return {
    createGustoCompany: mutate,
    createGustoCompanyAsync: mutateAsync,
    ...rest,
  };
};

export const useConnectGustoCompany = (
  accountId: string,
  updateProps?: UseMutationOptions<unknown, unknown, string>,
) => {
  const { mutateAsync, mutate, ...rest } = useMutation<
    unknown,
    unknown,
    string
  >((uuid: string) => connectGustoCompany(accountId, uuid), {
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries(['account', accountId]),
        queryClient.invalidateQueries(['gustoCompanies']),
      ]),
    ...updateProps,
  });
  return {
    connectGustoCompanyAsync: mutateAsync,
    connectGustoCompany: mutate,
    ...rest,
  };
};

export const useDisconnectGustoCompany = (
  accountId: string,
  updateProps?: UseMutationOptions,
) => {
  const { mutateAsync, mutate, ...rest } = useMutation(
    () => disconnectGustoCompany(accountId),
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries(['account', accountId]),
          queryClient.invalidateQueries(['gustoTokenHealth', accountId]),
          queryClient.invalidateQueries(['gustoCompanies']),
        ]),
      ...updateProps,
    },
  );

  return {
    disconnectGustoCompanyAsync: mutateAsync,
    disconnectGustoCompany: mutate,
    ...rest,
  };
};

export const useDisconnectGustoUser = (
  queryProps?: UseMutationOptions<unknown, unknown, boolean>,
) => {
  const { mutateAsync, mutate, ...rest } = useMutation(
    (deletePrimary: boolean) => disconnectGustoUser(deletePrimary),
    {
      onSuccess: () => queryClient.invalidateQueries(['gustoCurrentAccount']),
      ...queryProps,
    },
  );
  return {
    disconnectGustoUserAsync: mutateAsync,
    disconnectGustoUser: mutate,
    ...rest,
  };
};

interface UseCreateGustoEmployeeVariables {
  companyUUID: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  middleInitial?: string;
  email: string;
  ssn: string;
}
export const useCreateGustoEmployee = (
  accountId: string,
  mutateProps?: UseMutationOptions<
    unknown,
    unknown,
    UseCreateGustoEmployeeVariables
  >,
) => {
  const { mutateAsync, mutate, ...rest } = useMutation<
    unknown,
    unknown,
    UseCreateGustoEmployeeVariables
  >(
    ({
      companyUUID,
      firstName,
      lastName,
      middleInitial,
      dateOfBirth,
      ssn,
      email,
    }) =>
      createGustoEmployee(accountId, companyUUID, {
        first_name: firstName,
        last_name: lastName,
        middle_initial: middleInitial,
        date_of_birth: dateOfBirth,
        ssn,
        email,
      }),
    {
      ...mutateProps,
    },
  );

  return {
    createGustoEmployee: mutate,
    createGustoEmployeeAsync: mutateAsync,
    ...rest,
  };
};

const defaultPayrollsResult = getDefaultApiListResp<Payroll[]>();
export const usePayrolls = (
  accountId: string | undefined,
  companyUUID: string | undefined,
  params?: GetPayrollsParams,
  options?: UseQueryOptions<ApiListResp<Payroll[]>>,
) => {
  const { page = 1, size = defaultTablePageSize, from, to } = params ?? {};
  const { data = defaultPayrollsResult, ...rest } = useQuery<
    ApiListResp<Payroll[]>
  >(
    ['payrolls', accountId, companyUUID, page, size, from, to],
    () => getPayrolls(accountId!, companyUUID!, omitBy(params, isUndefined)),
    {
      enabled: companyUUID != null && accountId != null,
      keepPreviousData: true,
      ...options,
    },
  );

  return {
    payrolls: data,
    ...rest,
  };
};

export const usePayStubDownload = (
  accountId: string | undefined,
  payrollId: string | undefined,
  employeeId: string | undefined,
  options?: UseQueryOptions<Blob>,
) =>
  useQuery<Blob>(
    ['downloadPayroll', accountId, payrollId, employeeId],
    () => downloadPayStub(accountId!, payrollId!, employeeId!),
    {
      enabled:
        accountId != null &&
        payrollId != null &&
        employeeId != null &&
        employeeId !== '',
      keepPreviousData: true,
      ...options,
    },
  );

export const useUpcomingPayrolls = (
  accountId: string | undefined,
  companyUUID: string | undefined,
  options?: UseQueryOptions<Payroll[]>,
) => {
  const { data = [], ...rest } = useQuery<Payroll[]>(
    ['upcomingPayrolls', accountId, companyUUID],
    () => getUpcomingPayrolls(accountId!, companyUUID!),
    {
      enabled: companyUUID != null && accountId != null,
      keepPreviousData: true,
      ...options,
    },
  );

  return {
    payrolls: data,
    ...rest,
  };
};

export const useCalculatePayroll = (accountId: string, payrollUUID: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    () => calculatePayroll(accountId, payrollUUID),
    {},
  );
  return {
    calculatePayroll: mutate,
    calculatePayrollAsync: mutateAsync,
    ...rest,
  };
};

export const usePlaidLinkToken = (accountId: string) =>
  useQuery<CreateLinkToken>(['linkToken', accountId], () =>
    createLinkToken(accountId),
  );

interface ConnectBankParams {
  publicToken: string;
  bankName: string;
  bankId: string;
}
export const useConnectBank = (
  accountId: string,
  companyUUID: string | undefined,
) => {
  const { mutate, mutateAsync, ...rest } = useMutation<
    unknown,
    unknown,
    ConnectBankParams
  >(
    ['connect-bank', accountId],
    ({ publicToken, bankName, bankId }) => {
      if (!companyUUID) throw Error('Company UUID not found');
      return connectBank(accountId, companyUUID, publicToken, bankName, bankId);
    },
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries([
            'payroll-onboarding-status',
            accountId,
          ]),
          queryClient.invalidateQueries(['company-bank-account', companyUUID]),
        ]),
    },
  );

  return { connectBank: mutate, connectBankAsync: mutateAsync, ...rest };
};

export const usePayrollOnboardingStatus = (
  accountId?: string,
  companyUUID?: string,
  options: UseQueryOptions<PayrollOnboardingStatus> = {},
) => {
  const { data, ...rest } = useQuery<PayrollOnboardingStatus>(
    ['payroll-onboarding-status', accountId],
    () => getPayrollOnboardingStatus(accountId!, companyUUID!),
    {
      ...options,
      enabled: accountId != null && companyUUID != null,
    },
  );

  return {
    onboardingStatus: data,
    ...rest,
  };
};

export const useCompanyBankAccount = (
  accountId: string,
  companyUUID?: string,
) => {
  const { data, ...rest } = useQuery(
    ['company-bank-account', companyUUID],
    () => getBankAccounts(accountId, companyUUID!),
    {
      enabled: accountId != null && companyUUID != null,
    },
  );
  return {
    bankAccounts: data,
    ...rest,
  };
};

export const useMigrationEPStatus = (
  accountId: string,
  companyUUID: string,
) => {
  const { data, ...rest } = useQuery(
    ['migration-status', companyUUID],
    () => isEmbeddedPayrollMigrated(accountId, companyUUID),
    {
      enabled: accountId != null && companyUUID != null,
    },
  );
  return {
    isMigrated: data,
    ...rest,
  };
};

export const useAcceptTermsOfServiceStatus = (
  accountId: string | undefined,
  companyUUID: string | undefined,
) => {
  const { data, ...rest } = useQuery(
    ['terms-of-service-status', companyUUID],
    () => getAcceptTermsOfServiceStatus(accountId!, companyUUID!),
    {
      enabled: companyUUID != null && accountId != null,
    },
  );
  return {
    accepted: data,
    ...rest,
  };
};

export const useAcceptTermsOfService = (companyUUID?: string) => {
  const { mutateAsync, mutate, ...rest } = useMutation(
    ['accept-terms', companyUUID],
    () => {
      if (companyUUID == null) {
        throw new Error('cannot accept terms of service: company not found');
      }
      return acceptTermsOfService(companyUUID);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(['terms-of-service-status', companyUUID]),
    },
  );
  return {
    acceptTermsOfService: mutate,
    acceptTermsOfServiceAsync: mutateAsync,
    ...rest,
  };
};

export const useMigrateToEmbeddedPayroll = (
  accountId: string,
  companyUUID: string,
) => {
  const { mutateAsync, mutate, ...rest } = useMutation(
    ['migrate-ep', companyUUID],
    () => {
      if (!companyUUID) {
        throw new Error('could not find company');
      }
      return migrateEmbeddedPayroll(companyUUID);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['account', accountId]);
        await queryClient.invalidateQueries(['progressTracker', accountId]);
      },
    },
  );
  return {
    migrateToEmbeddedPayroll: mutate,
    migrateToEmbeddedPayrollAsync: mutateAsync,
    ...rest,
  };
};

export const usePayrollOnboardingFlow = (
  accountId: string,
  companyUUID: string,
) => {
  const { data, ...rest } = useQuery(['payroll-onboarding', accountId], () =>
    getOnboardingFlow(accountId, companyUUID),
  );
  return {
    onboardingUrl: data,
    ...rest,
  };
};

export const usePayrollReady = (accountId: string) =>
  useQuery(['payroll-ready', accountId], () => checkPayrollReady(accountId));

export const useStartCustomerOnboarding = (accountId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ['start-customer-onboarding', accountId],
    () => startCustomerOnboarding(accountId),
  );

  return {
    startCustomerOnboarding: mutate,
    startCustomerOnboardingAsync: mutateAsync,
    ...rest,
  };
};

export const useReviewAndConfirm = (
  mutationProps?: UseMutationOptions<unknown, unknown, unknown>,
) =>
  useMutation(
    (accountId: string) => submitReviewAndConfirm(accountId),
    mutationProps,
  );

export const useCompanyForms = (accountId: string) => {
  const { data, ...rest } = useQuery(['company-forms', accountId], () =>
    getCompanyForms(accountId),
  );
  return {
    forms: data,
    ...rest,
  };
};

export const useCompanyFormPdf = (accountId: string, formId: string) => {
  const { data, ...rest } = useQuery(
    ['company-form-pdf', accountId, formId],
    () => getCompanyFormPdf(accountId, formId),
  );
  return {
    documentUrl: data,
    ...rest,
  };
};

export const useDownloadCompanyForm = (accountId: string, formId: string) =>
  useQuery(['download-company-form', accountId, formId], () =>
    downloadCompanyFormPdf(accountId, formId),
  );

export const useSignCompanyForm = (accountId: string, formId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ['sign-company-form', accountId, formId],
    (data: SignCompanyFormBody) => signCompanyForm(accountId, formId, data),
  );
  return {
    signCompanyForm: mutate,
    signCompanyFormAsync: mutateAsync,
    ...rest,
  };
};

export const useCompleteOnboarding = (
  accountId: string,
  options: UseMutationOptions = {},
) => {
  const { mutateAsync, mutate, ...rest } = useMutation(
    ['complete-onboarding', accountId],
    () => completeOnboarding(accountId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(['payroll-onboarding-status', accountId]),
      ...options,
    },
  );
  return {
    completeOnboarding: mutate,
    completeOnboardingAsync: mutateAsync,
    ...rest,
  };
};

export const useGustoWebhooks = () => {
  const { data, ...rest } = useQuery(['gusto-webhook'], () => getWebhook());
  return {
    webhooks: data,
    ...rest,
  };
};

export const useCreateGustoWebhooks = () => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ['create-gusto-webhook'],
    createWebhook,
  );
  return {
    createWebhook: mutate,
    createWebhookAsync: mutateAsync,
    ...rest,
  };
};

export const useCreatePayrollFlow = (
  accountId: string,
  flowType: PayrollFlowType,
) => {
  const { data, ...rest } = useQuery(['create-payroll-flow', accountId], () =>
    createPayrollFlow(accountId, flowType),
  );
  return {
    url: data,
    ...rest,
  };
};

export const useInaccessibleCompanies = () => {
  const { data, ...rest } = useQuery(['inaccessible-companies'], () =>
    getInaccessibleCompanies(),
  );
  return {
    companies: data,
    ...rest,
  };
};

export const useAuthorizationStatusForAccount = (accountId: string) => {
  const { data, ...rest } = useQuery(
    ['authorization-gusto-status', accountId],
    () => getAuthorizationStatusForAccount(accountId),
    {
      enabled: accountId !== '',
    },
  );

  return {
    authorizationStatus: data,
    ...rest,
  };
};

export const useAuthorizeGustoForAccount = (accountId: string) => {
  const { mutateAsync, mutate, ...rest } = useMutation(
    ['authorize-gusto', accountId],
    (code: string) => authorizeGustoForAccount(accountId, code),
  );
  return {
    authorizeGustoForAccount: mutate,
    authorizeGustoForAccountAsync: mutateAsync,
    ...rest,
  };
};

export const useDisconnectGustoCompanyForAccount = (accountId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ['disconnect-gusto-company', accountId],
    () => deleteGustoAuthorizationForAccount(accountId),
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries([
            'authorization-gusto-status',
            accountId,
          ]),
          queryClient.invalidateQueries(['gustoTokenHealth', accountId]),
          queryClient.invalidateQueries(['account', accountId]),
        ]),
    },
  );
  return {
    disconnectGustoCompanyForAccount: mutate,
    disconnectGustoCompanyForAccountAsync: mutateAsync,
    ...rest,
  };
};

export const useCancelPayroll = () => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ['cancel-payroll'],
    ({
      accountId,
      payrollUUID,
      reason,
    }: {
      accountId: string;
      payrollUUID: string;
      reason: string;
    }) => cancelPayroll(accountId, payrollUUID, reason),
  );

  return {
    cancelPayroll: mutate,
    cancelPayrollAsync: mutateAsync,
    ...rest,
  };
};

export const useCreateRequestChangeTicket = (
  accountId: string,
  payrollUUID: string,
) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ['request-change-payroll', accountId],
    ({ date, reason }: { date?: string; reason: string }) =>
      requestChangePayroll(accountId, payrollUUID, reason, date),
  );

  return {
    createChangeTicket: mutate,
    createChangeTicketAsync: mutateAsync,
    ...rest,
  };
};

export const useEmployees = (
  accountId: string,
  options: UseQueryOptions<unknown, unknown, Employee[]> = {},
) => {
  const { data, ...rest } = useQuery<unknown, unknown, Employee[]>(
    ['employees', accountId],
    () => getEmployees(accountId),
    options,
  );
  return {
    employees: data || [],
    ...rest,
  };
};

export const useW2Document = (
  accountId: string,
  employeeId: string | null,
  options: UseQueryOptions<unknown, unknown, Blob | undefined> = {},
) => {
  const { data, ...rest } = useQuery<unknown, unknown, Blob | undefined>(
    ['w2-document', accountId, employeeId],
    () => getEmployeeW2(accountId, employeeId!),
    options,
  );
  return {
    w2Document: data,
    ...rest,
  };
};

export const useW2AvailableDocuments = (
  businessId: string,
  employeeId: string,
) => {
  const { data, ...rest } = useQuery(
    ['w2-documents', businessId, employeeId],
    () => getAvailableW2Documents(businessId, employeeId),
    {
      enabled: !!businessId && !!employeeId,
    },
  );

  return {
    w2Documents: data,
    ...rest,
  };
};

export const useEmployeeDocument = (
  businessId: string,
  employeeId: string,
  documentUUID: string,
) => {
  const { data, ...rest } = useQuery(
    ['employee-document', businessId, employeeId, documentUUID],
    () => getEmployeeDocument(businessId, employeeId, documentUUID),
    {
      enabled: !!businessId && !!employeeId && !!documentUUID,
    },
  );
  return {
    document: data,
    ...rest,
  };
};

export const useOfficers = (accountId: string) => {
  const { data, ...rest } = useQuery(['officers', accountId], () =>
    getOfficers(accountId),
  );
  return {
    officers: data ?? [],
    ...rest,
  };
};

export const useSaveOfficers = (accountId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ['save-officers', accountId],
    (officers: string[]) => saveOfficers(accountId, officers),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['officers', accountId]);
      },
    },
  );

  return {
    saveOfficers: mutate,
    saveOfficersAsync: mutateAsync,
    ...rest,
  };
};

export const useSetDisabledPayrollFeature = (accountId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (disabledPayroll: boolean) =>
      setDisabledPayrollFeature(accountId, disabledPayroll),
  );
  return {
    setDisabledPayrollFeature: mutate,
    setDisabledPayrollFeatureAsync: mutateAsync,
    ...rest,
  };
};
