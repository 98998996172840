// eslint-disable-next-line import/prefer-default-export
type ConstantsType = {
  TERM_AND_CONDITION_VERSION: string;
  USER_ROLES: {
    ADMIN: 'Admin';
    CUSTOMER: 'Customer';
    FULFILLMENT: 'Fulfillment';
    SUPER_ADMIN: 'SuperAdmin';
    SUCCESS: 'Success';
    CUSTOMER_SERVICE: 'CustomerService';
  };
  DEFAULT_DATE_TIME_FORMAT: string;
};

export type Role =
  | 'any'
  | 'staff'
  | 'Customer'
  | 'Admin'
  | 'SuperAdmin'
  | 'Fulfillment'
  | 'Success'
  | 'CustomerService';

export const CONSTANTS: ConstantsType = {
  TERM_AND_CONDITION_VERSION: '2021-09-06', // YYYY-MM-DD
  USER_ROLES: {
    ADMIN: 'Admin',
    CUSTOMER: 'Customer',
    FULFILLMENT: 'Fulfillment',
    SUPER_ADMIN: 'SuperAdmin',
    SUCCESS: 'Success',
    CUSTOMER_SERVICE: 'CustomerService',
  },
  DEFAULT_DATE_TIME_FORMAT: 'DD-MM-YYYY HH:mm:ss',
};

export const ENTITY_MAPPING = {
  sole_prop: 'Sole-Prop',
  s_corp: 'S-CORP',
  c_corp: 'C-CORP',
  llc: 'LLC',
};

export const ENTITY_OPTIONS = [
  {
    id: ENTITY_MAPPING.sole_prop,
    name: 'Sole Proprietorship',
    value: 'sole_prop',
    desc: 'Unincorporated business owned and run by one individual.',
  },
  {
    id: ENTITY_MAPPING.llc,
    name: 'LLC',
    value: 'llc',
    desc: "Business structure whereby the owners are not personally liable for the company's debts or liabilities.",
  },
  {
    id: ENTITY_MAPPING.s_corp,
    name: 'S-Corp',
    value: 's_corp',
    desc: 'An Entity that elects to pass corporate income, losses, deductions, and credits through to its shareholders for federal tax purposes.',
  },
  {
    id: ENTITY_MAPPING.c_corp,
    name: 'C-Corp',
    value: 'c_corp',
    desc: 'A C-Corporation is a legal business entity taxed as a corporation. The owners, or shareholders, are taxed separately from the entity.',
  },
];

export const GET_STARTED_DIALOG_TIMEOUT = 3000;

export const CHURN_ZERO_EVENTS = {
  LOGIN: 'churnZeroLoginEvent',
};

export const CHURN_ZERO_EVENT_NAMES = {
  LOGIN: 'Login',
  DOCUMENT_UPLOAD: 'DocumentUpload',
  DOCUMENT_DOWNLOAD: 'DocumentDownload',
  ADD_TRANSACTION: 'AddTransaction',
  GUSTO_TERMS_OF_SERVICE: 'GustoTermsOfService',
  VISIT_PAGE: 'VisitPage',
  VISITED_KNOWLEDGE_BASE: 'VisitedKnowledgeBase',
};

export const ALL_ALLOWED_FILES =
  '.txt, .rtf, .csv, .doc, .docx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/PNG, image/JPEG, application/PDF, .mp4';

export const CSV_FILES =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export const DEFAULT_ALLOWED_FILES = 'image/PNG, image/JPEG, application/PDF';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const NOTE_MAX_LENGTH = 500;
export const DOCUMENT_NOTE_MAX_LENGTH = 200;
export const TAX_FORM_NOTE_MAX_LENGTH = 9999;

export const defaultTablePageSize = 25;
export const defaultTableRowsPerPageOptions = [25, 50, 100];
