import { FLAGS } from 'hooks/useFeatureFlag';
import {
  FeatureName,
  FormationFeature,
  TransactionDataSource,
} from 'services/features';
import { IAccount } from 'models/account';
import { IAction, ISubAction } from './constant';

export const FEATURE_PERMISSION = {
  DEV: 100,
  BETA: 50,
  LAUNCH: 10,
  PUBLISH: 0,
  USER: 0,
};

const getUserPermission = (flags: Flags): number => {
  if (flags[FLAGS.DEV]) {
    return FEATURE_PERMISSION.DEV;
  }
  if (flags[FLAGS.BETA]) {
    return FEATURE_PERMISSION.BETA;
  }
  if (flags[FLAGS.LAUNCH]) {
    return FEATURE_PERMISSION.LAUNCH;
  }
  if (flags[FLAGS.PUBLISH]) {
    return FEATURE_PERMISSION.PUBLISH;
  }
  return FEATURE_PERMISSION.USER;
};

const getPermissionNumber = (featureFlag: string): number => {
  switch (featureFlag) {
    case FLAGS.DEV:
      return FEATURE_PERMISSION.DEV;
    case FLAGS.BETA:
      return FEATURE_PERMISSION.BETA;
    case FLAGS.LAUNCH:
      return FEATURE_PERMISSION.LAUNCH;
    case FLAGS.PUBLISH:
      return FEATURE_PERMISSION.PUBLISH;
    default:
      return FEATURE_PERMISSION.USER;
  }
};

export const isFormationsFeature = (featureFlag: string): boolean =>
  [FeatureName.DataSourceToggle].includes(featureFlag as FeatureName) ||
  [TransactionDataSource.Hurdlr, TransactionDataSource.Xero].includes(
    featureFlag as TransactionDataSource,
  );

const checkFormationFeatureLogic = (
  featureList: FormationFeature[],
  featureFlag: string = '',
): boolean => {
  if ([FeatureName.DataSourceToggle].includes(featureFlag as FeatureName)) {
    // this is feature is controlled by Formations database
    const formationsFeatureFlag = featureList.find(
      (f) => f.featureName === featureFlag,
    );
    return formationsFeatureFlag ? formationsFeatureFlag.enabled : false;
  }
  if (
    [TransactionDataSource.Hurdlr].includes(
      featureFlag as TransactionDataSource,
    )
  ) {
    const formationsFeatureFlag = featureList.find(
      (f) => f.featureName === FeatureName.DataSourceToggle,
    );
    return formationsFeatureFlag
      ? formationsFeatureFlag.data?.source !== TransactionDataSource.Xero
      : true;
  }
  if (
    [TransactionDataSource.Xero].includes(featureFlag as TransactionDataSource)
  ) {
    const formationsFeatureFlag = featureList.find(
      (f) => f.featureName === FeatureName.DataSourceToggle,
    );
    return formationsFeatureFlag
      ? formationsFeatureFlag.data?.source === TransactionDataSource.Xero
      : false;
  }
  return false;
};

export const checkFeaturePermission = (
  flags: Flags,
  featureList: FormationFeature[],
  featureFlag: string = '',
): boolean => {
  const userPermission = getUserPermission(flags);
  if (isFormationsFeature(featureFlag)) {
    return checkFormationFeatureLogic(featureList, featureFlag);
  }
  const permissionNeeded = getPermissionNumber(featureFlag);
  switch (featureFlag) {
    case FLAGS.FEEDBACKFORM:
    case FLAGS.CHECKINSURVEY:
    case FLAGS.OILLIST:
    case FLAGS.VENDOR_MANAGEMENT:
      return !!flags[featureFlag];
    case FLAGS.DEV:
    case FLAGS.BETA:
    case FLAGS.LAUNCH:
    case FLAGS.PUBLISH:
    default:
      return userPermission >= permissionNeeded;
  }
};

export interface Flags {
  [key: string]: boolean;
}

export const filterVisibleActions = (
  actions: Array<IAction | ISubAction>,
  flags: Flags,
  featureList: FormationFeature[],
) =>
  actions.filter((action) =>
    checkFeaturePermission(flags, featureList, action.featureFlag),
  );

export const calculateNotificationCount = (
  id: string,
  customerAccount: IAccount | undefined,
) => {
  let count = 0;

  switch (id) {
    case 'open-item-list':
    case 'taxesTop':
      count =
        (customerAccount?.oilUnreadCustomerNoteItemsCount ?? 0) +
        (customerAccount?.oilPendingReviewItemsCount ?? 0);
      break;
    default:
      break;
  }

  return count;
};
