import { AuthService } from 'services/auth';
import { CONFIG } from 'config';
import {
  GustoCompanyDetail,
  Payroll,
  PayrollResp,
  IMonthlyWagesData,
  PayrollOnboardingStatus,
  PayrollOnboardingStatusResp,
  CompanyBankAccountResp,
  CompanyBankAccount,
  FormPDFResp,
  CompanyFormResp,
  CompanyForm,
  SignCompanyFormBody,
  WebhookResp,
  Webhook,
  PayrollFlowType,
  GustoCompanySimple,
  GustoAuthorizationStatusResp,
  GustoAuthorizationStatus,
  Employee,
  Officer,
} from 'models/payroll';
import { ApiListResp } from 'models/api';
import { AxiosResponse } from 'axios';
import { defaultTablePageSize } from 'constants/common';
import moment from 'moment';
import { payrollService, api } from './axios';

const redirectURI = CONFIG.gustoRedirectUri;

export interface GustoAccountResp {
  id: number;
  email: string;
}
export const getCurrentGustoAccount = async (): Promise<GustoAccountResp[]> =>
  payrollService.get<GustoAccountResp[]>('/me').then((resp) => resp.data);

export interface GustoTokenHealthResp {
  isConnected: boolean;
  message: string;
}
export const getGustoTokenHealth = async (
  accountId: string,
): Promise<GustoTokenHealthResp> =>
  payrollService
    .get<{ is_connected: boolean; message: string }>(
      `/accounts/${accountId}/gusto-token-health`,
    )
    .then((resp) => ({
      isConnected: resp.data.is_connected,
      message: resp.data.message,
    }));

export interface GetGustoCompaniesParams {
  connected?: boolean;
}
export interface GustoCompanyResp {
  id: number;
  name: string;
  uuid: string;
}
export const getGustoCompanies = async (
  params: GetGustoCompaniesParams = {},
): Promise<GustoCompanyResp[]> =>
  payrollService
    .get<GustoCompanyResp[]>('/companies', { params })
    .then((resp) => resp.data);

export const processGustoAuth = async (
  code: string,
  isPrimaryAccessToken: boolean,
) => {
  const userId = AuthService.userId();
  return payrollService.post(
    '/auth',
    {},
    {
      params: {
        code,
        redirectURI,
        userId,
        isPrimary: isPrimaryAccessToken,
      },
    },
  );
};

const transformGustoAuthorizationStatus = (
  resp: GustoAuthorizationStatusResp,
): GustoAuthorizationStatus => ({
  authorized: resp.authorized,
  authorizedType: resp.authorized_type,
  companyUUID: resp.company_uuid,
  companyName: resp.company_name,
  error: resp.error,
  errorCode: resp.error_code,
});
export const getAuthorizationStatusForAccount = async (
  accountId: string,
): Promise<GustoAuthorizationStatus> =>
  payrollService
    .get<GustoAuthorizationStatusResp>(`/auth/${accountId}`)
    .then((resp) => transformGustoAuthorizationStatus(resp.data));

export const authorizeGustoForAccount = async (
  accountId: string,
  code: string,
) =>
  payrollService.post<{ code: string; redirect_uri: string }, void>(
    `/auth/${accountId}`,
    {
      code,
      redirect_uri: redirectURI,
    },
  );

export const connectCompanyForAccount = async (
  accountId: string,
  companyUUID: string,
  companyName: string,
) =>
  payrollService.post(`/auth/${accountId}/companies`, {
    company_uuid: companyUUID,
    company_name: companyName,
  });

export const deleteGustoAuthorizationForAccount = async (accountId: string) =>
  payrollService.delete(`/auth/${accountId}`);

export const connectGustoCompany = async (accountId: string, uuid: string) =>
  payrollService.post(`/accounts/${accountId}/connect-company`, {
    gustoCompanyUUID: uuid,
  });

export const disconnectGustoCompany = async (accountId: string) =>
  payrollService.delete(`/accounts/${accountId}/company`);

export const createGustoCompany = async (accountId: string) =>
  payrollService.post(`/accounts/${accountId}/company`);

export interface GustoCompanyDetailResp {
  id: number;
  name: string;
  uuid: string;
  ein: string;
  // eslint-disable-next-line camelcase
  entity_type: string;
  // eslint-disable-next-line camelcase
  trade_name: string;
  // eslint-disable-next-line camelcase
  is_partner_managed: boolean;
}
const transformCompanyDetail = (
  resp: GustoCompanyDetailResp,
): GustoCompanyDetail => ({
  id: resp.id,
  uuid: resp.uuid,
  name: resp.name,
  ein: resp.ein,
  entityType: resp.entity_type,
  tradeName: resp.trade_name,
  isPartnerManaged: resp.is_partner_managed,
});

export const getGustoCompanyByAdmin = async (
  companyUUID: string,
): Promise<GustoCompanyDetail> =>
  payrollService
    .get<GustoCompanyDetailResp>(`/companies/${companyUUID}`)
    .then((resp) => transformCompanyDetail(resp.data));

export const getGustoCompany = async (
  accountId: string,
): Promise<GustoCompanyDetail> =>
  payrollService
    .get<GustoCompanyDetailResp>(`/accounts/${accountId}/company`)
    .then((resp) => transformCompanyDetail(resp.data));

export const disconnectGustoUser = async (
  deletePrimary: boolean,
): Promise<GustoAccountResp> =>
  payrollService
    .delete<GustoAccountResp>('/auth', { params: { deletePrimary } })
    .then((resp) => resp.data);

interface CreateGustoEmployeeParams {
  // eslint-disable-next-line camelcase
  first_name?: string;
  // eslint-disable-next-line camelcase
  middle_initial?: string;
  // eslint-disable-next-line camelcase
  last_name?: string;
  email?: string;
  // eslint-disable-next-line camelcase
  date_of_birth?: string;
  ssn?: string;
}
export const createGustoEmployee = async (
  accountId: string | undefined,
  companyUUID: string | undefined,
  params: CreateGustoEmployeeParams,
) => {
  if (!accountId || !companyUUID) {
    payrollService
      .post(`/accounts/${accountId}/companies/${companyUUID}/employees`, params)
      .then((resp) => resp.data);
  }
};

export const fallbackZero = (val: number | undefined): number => val ?? 0;
export const fallbackNull = (val: number | undefined | null): number | null =>
  val ?? null;

export const transformPayrollResp = (resp: PayrollResp): Payroll => ({
  companyId: resp.company_id,
  companyUUID: resp.company_uuid,
  id: resp.id,
  offCycle: resp.off_cycle ?? false,
  offCycleReason: resp.off_cycle_reason ?? '',
  payrollId: resp.payroll_id,
  payrollUUID: resp.payroll_uuid,
  grossEarning: resp.gross_earning ?? 0,
  officerNetPay: resp.officer_net_earning ?? 0,
  calculatedAt: resp.calculated_at ?? null,
  totals: {
    companyDebit: fallbackNull(resp.totals.company_debit),
    grossPay: fallbackZero(resp.totals.gross_pay),
    netPay: fallbackZero(resp.totals.net_pay),
    netPayDebit: fallbackZero(resp.totals.net_pay_debit),
    reimbursements: fallbackZero(resp.totals.reimbursements),
    employerTaxes: fallbackZero(resp.totals.employer_taxes),
    benefits: fallbackZero(resp.totals.benefits),
  },
  employeeCompensations: resp.employee_compensations?.map((comp) => ({
    employeeId: comp.employee_id,
    employeeUUID: comp.employee_uuid,
    grossPay: fallbackZero(comp.gross_pay),
    netPay: fallbackZero(comp.net_pay),
    paymentMethod: comp.payment_method,
  })),
  payPeriod: {
    startDate: resp.pay_period.start_date,
    endDate: resp.pay_period.end_date,
  },
  processed: resp.processed,
  processedDate: resp.processed_date,
  checkDate: resp.check_date,
  cancelRequestDate: resp.cancel_request_date,
  changeRequestDate: resp.change_request_date,
});
const transformPayrollListResp = (
  page: ApiListResp<PayrollResp[]>,
): ApiListResp<Payroll[]> => ({
  data: page.data.map(transformPayrollResp),
  pageInfo: page.pageInfo,
});

export interface GetPayrollsParams {
  page?: number;
  size?: number;
  from?: string;
  to?: string;
}
export const getPayrolls = (
  accountId: string,
  companyUUID: string,
  params?: GetPayrollsParams,
): Promise<ApiListResp<Payroll[]>> => {
  const { page = 1, size = defaultTablePageSize, from, to } = params ?? {};
  return payrollService
    .get<ApiListResp<PayrollResp[]>>(
      `/accounts/${accountId}/companies/${companyUUID}/payrolls`,
      { params: { page, size, from, to } },
    )
    .then((data) => data.data)
    .then((pageResult) => transformPayrollListResp(pageResult));
};

export const getUpcomingPayrolls = (
  accountId: string,
  companyUUID: string,
): Promise<Payroll[]> =>
  payrollService
    .get<PayrollResp[]>(
      `/accounts/${accountId}/companies/${companyUUID}/upcoming-payrolls`,
    )
    .then((data) => data.data.map(transformPayrollResp))
    .then((data) =>
      data.filter((item) => moment(item.checkDate).isSameOrAfter(new Date())),
    );

export const calculatePayroll = (accountId: string, payrollUUID: string) =>
  payrollService.put(
    `/accounts/${accountId}/payrolls/${payrollUUID}/calculate`,
  );

export const downloadPayStub = async (
  accountId: string,
  payrollId: string,
  employeeId: string,
): Promise<Blob> => {
  const { data } = await payrollService.get<Blob>(
    `accounts/${accountId}/payrolls/${payrollId}/employees/${employeeId}/download`,
    {
      responseType: 'blob',
      timeout: 30000,
    },
  );
  return data;
};

export const getMonthlyWages = async (
  accountId: string,
): Promise<IMonthlyWagesData[]> =>
  payrollService
    .get<IMonthlyWagesData[]>(`/accounts/${accountId}/monthly-wages`)
    .then((data) => data.data);

type CreateLinkTokenResp = {
  // eslint-disable-next-line camelcase
  link_token: string;
  expiration: string;
};
export type CreateLinkToken = {
  linkToken: string;
  expiration: string;
};
export const createLinkToken = async (
  accountId: string,
): Promise<CreateLinkToken> =>
  payrollService
    .get<CreateLinkTokenResp>(`accounts/${accountId}/plaid-token`)
    .then((resp) => ({
      linkToken: resp.data.link_token,
      expiration: resp.data.expiration,
    }));

export const connectBank = async (
  accountId: string,
  companyUUID: string,
  publicToken: string,
  bankName: string,
  bankId: string,
) =>
  payrollService.post(
    `accounts/${accountId}/companies/${companyUUID}/bank-account`,
    {
      public_token: publicToken,
      institution_name: bankName,
      institution_id: bankId,
    },
  );

const transformCompanyBankAccount = (
  resp: CompanyBankAccountResp,
): CompanyBankAccount => ({
  institutionName: resp.institution_name ?? 'N/A',
  accountType: resp.account_type,
  hiddenAccountNumber: resp.hidden_account_number,
  routingNumber: resp.routing_number,
  uuid: resp.uuid,
  verificationStatus: resp.verification_status,
  verificationType: resp.verification_type,
  companyUUID: resp.company_uuid,
});
export const getBankAccounts = async (
  accountId: string,
  companyUUID: string,
): Promise<CompanyBankAccount[]> =>
  payrollService
    .get<CompanyBankAccountResp[]>(
      `accounts/${accountId}/companies/${companyUUID}/bank-accounts`,
    )
    .then((resp) => resp.data.map(transformCompanyBankAccount));

const transformOnboardingStatus = (
  resp: PayrollOnboardingStatusResp,
): PayrollOnboardingStatus => ({
  addAddresses: resp.add_addresses,
  addBankInfo: resp.add_bank_info,
  addEmployees: resp.add_employees,
  federalTaxSetup: resp.federal_tax_setup,
  onboardingCompleted: resp.onboarding_completed,
  payrollSchedule: resp.payroll_schedule,
  selectIndustry: resp.select_industry,
  signAllForms: resp.sign_all_forms,
  stateSetup: resp.state_setup,
  verifyBankInfo: resp.verify_bank_info,
  startedCustomerOnboarding: resp.started_customer_onboarding,
  reviewOnboardingData: resp.review_onboarding_data,
  approveCompany: resp.approve_company,
  createSignatories: resp.create_signatories,
});
export const getPayrollOnboardingStatus = async (
  accountId: string,
  companyUUID: string,
): Promise<PayrollOnboardingStatus> =>
  payrollService
    .get<PayrollOnboardingStatusResp>(
      `accounts/${accountId}/companies/${companyUUID}/onboarding-status`,
    )
    .then((resp) => transformOnboardingStatus(resp.data));

export const isEmbeddedPayrollMigrated = async (
  accountId: string,
  companyUUID: string,
): Promise<boolean> =>
  payrollService
    // eslint-disable-next-line camelcase
    .get<{ migration_status: boolean }>(
      `accounts/${accountId}/companies/${companyUUID}/migration-status`,
    )
    .then((resp) => resp.data.migration_status === true);

export const acceptTermsOfService = async (
  companyUUID: string,
): Promise<void> =>
  payrollService.post(
    `/partner-managed-companies/${companyUUID}/accept-terms-of-service`,
  );

export const migrateEmbeddedPayroll = async (
  companyUUID: string,
): Promise<void> =>
  payrollService.put(`/partner-managed-companies/${companyUUID}/migrate`);

export const getAcceptTermsOfServiceStatus = async (
  accountId: string,
  companyUUID: string,
): Promise<boolean> =>
  payrollService
    // eslint-disable-next-line camelcase
    .get<{ latest_terms_accepted: boolean }>(
      `/accounts/${accountId}/companies/${companyUUID}/terms-of-service-status`,
    )
    .then((resp) => resp.data.latest_terms_accepted || false);

export const getOnboardingFlow = async (
  accountId: string,
  companyUUID: string,
): Promise<string> =>
  payrollService
    // eslint-disable-next-line camelcase
    .post<{ url: string }>(
      `/accounts/${accountId}/companies/${companyUUID}/onboarding-flow`,
    )
    .then((resp) => resp.data.url || '');

export const checkPayrollReady = async (
  accountId: string,
): Promise<{ string: boolean }> =>
  payrollService
    .get(`/accounts/${accountId}/payroll-ready`)
    .then((resp) => resp.data);

export const startCustomerOnboarding = async (accountId: string) =>
  payrollService.post(`/accounts/${accountId}/customer-onboarding`);

export const submitReviewAndConfirm = async (accountId: string) =>
  payrollService
    .post(`accounts/${accountId}/confirm-onboarding`)
    .then((resp) => resp.data);

export const getCompanyForms = async (
  accountId: string,
): Promise<CompanyForm[]> =>
  payrollService
    .get<CompanyFormResp[]>(`/accounts/${accountId}/company-forms`)
    .then((resp) =>
      resp.data.map((item) => ({
        requiresSigning: item.requires_signing,
        ...item,
      })),
    );

export const getCompanyFormPdf = async (accountId: string, formId: string) =>
  payrollService
    .get<FormPDFResp>(`/accounts/${accountId}/forms/${formId}/pdf`)
    .then((resp) => resp.data.document_url);

export const downloadCompanyFormPdf = async (
  accountId: string,
  formId: string,
) =>
  payrollService
    .get<Blob>(`/accounts/${accountId}/forms/${formId}/download`, {
      responseType: 'blob',
      timeout: 30000,
    })
    .then((resp) => resp.data);

export const signCompanyForm = async (
  accountId: string,
  formId: string,
  data: SignCompanyFormBody,
) => payrollService.put(`/accounts/${accountId}/forms/${formId}/sign`, data);

export const completeOnboarding = async (accountId: string) =>
  payrollService.put(`/accounts/${accountId}/complete-onboarding`);

const transformWebhook = (item: WebhookResp): Webhook => ({
  subscriptionTypes: item.subscription_types,
  status: item.status,
  uuid: item.uuid,
});

export const getWebhook = async () =>
  payrollService
    .get<WebhookResp[]>('/subscribe')
    .then((resp) => resp.data.map(transformWebhook));

export const createWebhook = async () =>
  payrollService.post<void>('/subscribe');

// eslint-disable-next-line camelcase
type CreatePayrollFlowBody = { flow_type: PayrollFlowType };
type CreatePayrollFlowResp = { url: string };
export const createPayrollFlow = async (
  accountId: string,
  flowType: PayrollFlowType,
) =>
  payrollService
    .post<CreatePayrollFlowBody, AxiosResponse<CreatePayrollFlowResp>>(
      `/accounts/${accountId}/flow`,
      {
        flow_type: flowType,
      },
    )
    .then((resp) => resp.data.url);

type GustoCompanySimpleResp = {
  uuid: string;
  name: string;
  // eslint-disable-next-line camelcase
  account_id: string;
};
export const getInaccessibleCompanies = async (): Promise<
  GustoCompanySimple[]
> =>
  payrollService
    .get<GustoCompanySimpleResp[]>(`/inaccessible-companies`)
    .then((resp) =>
      resp.data.map((item) => ({
        uuid: item.uuid,
        name: item.name,
        accountId: item.account_id,
      })),
    );

export const cancelPayroll = async (
  accountId: string,
  payrollId: string,
  reason: string,
) =>
  payrollService.post(`/accounts/${accountId}/payrolls/${payrollId}/cancel`, {
    reason,
  });

export const requestChangePayroll = async (
  accountId: string,
  payrollUUID: string,
  reason: string,
  date?: string,
) =>
  payrollService.post(`/accounts/${accountId}/payrolls/${payrollUUID}/change`, {
    date,
    reason,
  });

export const getEmployees = async (accountId: string): Promise<Employee[]> =>
  payrollService
    .get<{ uuid: string; first_name: string; last_name: string }[]>(
      `/accounts/${accountId}/employees`,
    )
    .then((resp) =>
      resp.data.map((item) => ({
        uuid: item.uuid,
        lastName: item.last_name,
        firstName: item.first_name,
      })),
    );

export const getEmployeeW2 = async (accountId: string, employeeUUID: string) =>
  payrollService
    .get<Blob>(`/accounts/${accountId}/employees/${employeeUUID}/w2/download`, {
      responseType: 'blob',
      timeout: 30000,
    })
    .then((resp) => resp.data);

export const getEmployeeDocument = async (
  businessId: string,
  employeeUUID: string,
  documentUUID: string,
) =>
  payrollService
    .get<Blob>(
      `/business/${businessId}/employees/${employeeUUID}/documents/${documentUUID}/download`,
      {
        responseType: 'blob',
        timeout: 30000,
      },
    )
    .then((resp) => resp.data);

interface OfficerResp {
  uuid: string;
  account_id: string;
  company_uuid: string;
  first_name: string;
  last_name: string;
  is_officer: boolean;
}

interface EmployeeDocumentResp {
  uuid: string;
  name: string;
  title: string;
  year: number;
}

export const getAvailableW2Documents = async (
  businessId: string,
  employeeUUID: string,
) =>
  payrollService
    .get<EmployeeDocumentResp[]>(
      `/business/${businessId}/employees/${employeeUUID}/w2/years`,
    )
    .then((resp) => resp.data);

export const getOfficers = async (accountId: string): Promise<Officer[]> =>
  payrollService
    .get<OfficerResp[]>(`/accounts/${accountId}/officers`)
    .then((resp) =>
      resp.data.map(
        (item) =>
          ({
            uuid: item.uuid,
            accountID: item.account_id,
            companyUUID: item.company_uuid,
            firstName: item.first_name,
            lastName: item.last_name,
            isOfficer: item.is_officer,
          } as Officer),
      ),
    );

export const saveOfficers = async (accountId: string, officers: string[]) =>
  payrollService.post(`/accounts/${accountId}/officers`, {
    officers,
  });

export const setDisabledPayrollFeature = async (
  accountId: string,
  disabledPayroll: boolean,
) =>
  api.post(`/payrolls/${accountId}/toggle-payroll`, {
    disabledPayroll,
  });
