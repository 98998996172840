// React app doesn't have a solution to updating configuration at deploy time.
/* The expectation is that the application will be rebuilt for that environment to allow for
variables interpolation. */
/* This logic is a workaround the limitation, backend URL and Auth0 config are inferred from
the windows.location.host */

export interface Config {
  apiUrl: string; // todo: For deprecation in the future
  apiBaseUrl: string;
  apiPayrollUrl: string;
  gustoClientId: string;
  gustoAuthUrl: string;
  gustoRedirectUri: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
  logoutUrl: string;
  suggestLoginUser: boolean;
  showNavBar: boolean;
  stripeClientId: string;
  enableDocuments: boolean;
  enablePlacesAutocomplete: boolean;
  googleAPIKey: string;
  sentry: {
    enabled: boolean;
    env: string;
  };
  environment: string;
  hubspotAccountId: string;
  launchDarkly: {
    clientId: string;
    SDKKey: string;
  };
  adobePDFPreviewKey: string;
  churnDomain: string;
  churnAppKey: string;
  churnSecretKey: string;
  churnSuccessLookUp: string;
  churnSuccessAuthCallback: string;
  userpilot: {
    apiKey: string;
  };
  hurdlr: {
    clientId: string;
    environment: string;
  };
  hellosign: {
    clientId: string;
    skipDomainVerification: boolean;
    debug: boolean;
  };
  stripe: {
    key: string;
    env: Environment;
    isTestMode: boolean;
  };
  squarespace: {
    clientId: string;
    redirectUrl: string;
  };
  enableProfitWell: boolean;
}

export enum Environment {
  LOCALHOST = 'localhost',
  DEV = 'dev01',
  STAGING = 'staging01',
  PRODUCTION = 'production',
}

const configByEnv: Record<string, Partial<Config>> = {
  dev01: {
    auth0Domain: 'formations-test.us.auth0.com',
    auth0ClientId: 'xBr3ytTSgR3NMixZMLwLBYzpLOiORCN2',
    auth0Audience: 'https://dev01.api.formationscorp.com/',
    apiPayrollUrl: 'https://payroll.dev.api.formationscorp.com',
    gustoClientId: 'rDF1-gsvmNSZswY_aE2GweP6QLsPlELkLAVfSa8H6DM',
    gustoAuthUrl: 'https://app.gusto-demo.com/oauth/authorize',
    gustoRedirectUri: 'https://dev01.app.formationscorp.com/gusto/callback',
    logoutUrl: 'https://dev01.app.formationscorp.com/logout',
    suggestLoginUser: false,
    showNavBar: true,
    stripeClientId:
      'pk_test_51IQh45IJhNbHfeWHDKEWWuYdUl74G1cZLLHxRDws6Te7waIrQZrla0jAGFc6s8H4PAGQC3Q6VMgADTWJvq53QqLM00AMyEO0H3',
    enableDocuments: true,
    enablePlacesAutocomplete: true,
    googleAPIKey: 'AIzaSyDNLVJn_Eg0L0e8TNZ7kaI1NIgoBkxdAtE',
    sentry: {
      enabled: true,
      env: 'development',
    },
    environment: 'dev01',
    hubspotAccountId: '22791610',
    launchDarkly: {
      clientId: '6177a09ec9dff82419892d28',
      SDKKey: 'sdk-2b4d88d1-365d-4b52-88dd-7b9afc38a96d',
    },
    adobePDFPreviewKey: '249c9eec454249499ea24fd698f0943e',
    churnDomain: 'formationscorp-dev',
    churnAppKey: '1!NdJ4pyKGx5YP19donFeInRArVwEP14rFwerGoyvQM1gt1030',
    churnSecretKey: 'formationsDev',
    churnSuccessLookUp:
      'https://formationscorp-dev.us2app.churnzero.net/SuccessCenterContactLookup',
    churnSuccessAuthCallback:
      'https://formationscorp-dev.us2app.churnzero.net/SuccessCenterCallback',
    userpilot: {
      apiKey: 'STG-NX-21a7e488',
    },
    hurdlr: {
      clientId: 'k3TO4Li3K3gbH4clt9G7a3o5wvLa5we4KX0Q73X2O9dk18HM5XWmoyUW035N',
      environment: 'SANDBOX',
    },
    hellosign: {
      clientId: '45576b9235702af69bef71248069ba36',
      skipDomainVerification: true,
      debug: true,
    },
    stripe: {
      key: 'pk_test_szomDXlY5yCd7ShhxAUv6bej00PbCPNZVc',
      env: Environment.DEV,
      isTestMode: true,
    },
    squarespace: {
      clientId: 'bSBDezw8CXlZ1gUoxEveKOAEYWGOf9mY',
      redirectUrl: 'https://dev01.app.formationscorp.com/squarespace/link',
    },
    enableProfitWell: false,
  },
  staging01: {
    auth0Domain: 'formations-test.us.auth0.com',
    auth0ClientId: 'NdtbMJYoJuYfzQo3wWsQ0TzdvuiOm8pB',
    auth0Audience: 'https://staging01.api.formationscorp.com/',
    apiPayrollUrl: 'https://payroll.staging.api.formationscorp.com',
    gustoClientId: 'rDF1-gsvmNSZswY_aE2GweP6QLsPlELkLAVfSa8H6DM',
    gustoAuthUrl: 'https://app.gusto-demo.com/oauth/authorize',
    gustoRedirectUri: 'https://staging01.app.formationscorp.com/gusto/callback',
    logoutUrl: 'https://staging01.app.formationscorp.com/logout',
    suggestLoginUser: false,
    showNavBar: true,
    stripeClientId:
      'pk_test_51IQh45IJhNbHfeWHDKEWWuYdUl74G1cZLLHxRDws6Te7waIrQZrla0jAGFc6s8H4PAGQC3Q6VMgADTWJvq53QqLM00AMyEO0H3',
    enableDocuments: true,
    enablePlacesAutocomplete: true,
    googleAPIKey: 'AIzaSyANeWJoIEFTpcMOX5ZjCmsrrtZYSjuHGNM',
    sentry: {
      enabled: true,
      env: 'staging',
    },
    environment: 'staging01',
    hubspotAccountId: '22791610',
    launchDarkly: {
      clientId: '6177a09ec9dff82419892d28',
      SDKKey: 'sdk-2b4d88d1-365d-4b52-88dd-7b9afc38a96d',
    },
    adobePDFPreviewKey: '49c7c1eadb1e4983a153fedd4ad1e109',
    churnDomain: 'formationscorp-dev',
    churnAppKey: '1!NdJ4pyKGx5YP19donFeInRArVwEP14rFwerGoyvQM1gt1030',
    churnSecretKey: 'formationsDev',
    churnSuccessLookUp:
      'https://formationscorp-dev.us2app.churnzero.net/SuccessCenterContactLookup',
    churnSuccessAuthCallback:
      'https://formationscorp-dev.us2app.churnzero.net/SuccessCenterCallback',
    userpilot: {
      apiKey: 'STG-NX-21a7e488',
    },
    hurdlr: {
      clientId: 'k3TO4Li3K3gbH4clt9G7a3o5wvLa5we4KX0Q73X2O9dk18HM5XWmoyUW035N',
      environment: 'SANDBOX',
    },
    hellosign: {
      clientId: '28ca26241991583c5152a4b2e8f4a29c',
      skipDomainVerification: false,
      debug: false,
    },
    stripe: {
      key: 'pk_test_szomDXlY5yCd7ShhxAUv6bej00PbCPNZVc',
      env: Environment.STAGING,
      isTestMode: true,
    },
    squarespace: {
      clientId: 'bSBDezw8CXlZ1gUoxEveKOAEYWGOf9mY',
      redirectUrl: 'https://staging01.app.formationscorp.com/squarespace/link',
    },
    enableProfitWell: false,
  },
  production: {
    auth0Domain: 'formations.us.auth0.com',
    auth0ClientId: 'd3ueIJgo9ZlSjWC1KRskzyLL5Zsu64pl',
    auth0Audience: 'https://api.formationscorp.com/',
    apiPayrollUrl: 'https://payroll.api.formationscorp.com',
    gustoClientId:
      'bc6bc4e79506ea72e42d1e84b2ae3d6894f4209cffb90fe6638c706584fe66b9',
    gustoAuthUrl: 'https://app.gusto.com/oauth/authorize',
    gustoRedirectUri: 'https://app.formationscorp.com/gusto/callback',
    logoutUrl: 'https://app.formationscorp.com/logout',
    suggestLoginUser: false,
    showNavBar: true,
    stripeClientId: 'pk_live_JMLiAZwHqCqpYFH5tuNwoDrw00TTvpaS40',
    enableDocuments: true,
    enablePlacesAutocomplete: true,
    googleAPIKey: 'AIzaSyBat--YWPbwqNfF_Q01-t7Ux5N2DJORmeg',
    sentry: {
      enabled: true,
      env: 'production',
    },
    environment: 'production',
    hubspotAccountId: '6637631',
    launchDarkly: {
      clientId: '6177a09ec9dff82419892d29',
      SDKKey: 'sdk-4daae81d-dce3-41d2-924c-6b680c7027a7',
    },
    adobePDFPreviewKey: '79200d1af9024494982b48d0e2ec4282',
    churnDomain: 'formationscorp',
    churnAppKey: '1!gsx09aAqBEY3IDM7bMEjjSfgvkGBoWqJM9p4Wyd5Sq4t1031',
    churnSecretKey: 'formationsProd',
    churnSuccessLookUp:
      'https://formationscorp.us2app.churnzero.net/SuccessCenterContactLookup',
    churnSuccessAuthCallback:
      'https://formationscorp.us2app.churnzero.net/SuccessCenterCallback',
    userpilot: {
      apiKey: 'NX-21a7e488',
    },
    hurdlr: {
      clientId: '1RMc32OhDlYemXGVrLLYbr817L4SCmM103o1tomUTh3Es2i04yYvX2qcn7Aq',
      environment: 'PROD',
    },
    hellosign: {
      clientId: 'e10a1c4cd24ab8bdcc8bb5e2adeceaef',
      skipDomainVerification: false,
      debug: false,
    },
    stripe: {
      key: 'pk_live_JMLiAZwHqCqpYFH5tuNwoDrw00TTvpaS40',
      env: Environment.PRODUCTION,
      isTestMode: false,
    },
    squarespace: {
      clientId: 'qLUXJJR4MHj6qKfe59pBKvd9t1Xv4obI',
      redirectUrl: 'https://app.formationscorp.com/squarespace/link',
    },
    enableProfitWell: true,
  },
};

const loadConfigFromUrl = (): Config => {
  const host = window.location.host.toLowerCase();
  if (host.startsWith('localhost')) {
    return {
      apiUrl: '',
      apiBaseUrl: '',
      apiPayrollUrl: 'https://payroll.dev.api.formationscorp.com',
      gustoClientId: 'rDF1-gsvmNSZswY_aE2GweP6QLsPlELkLAVfSa8H6DM',
      gustoAuthUrl: 'https://app.gusto-demo.com/oauth/authorize',
      gustoRedirectUri:
        'https://41fb-2606-40-3f7-18dd-00-1060-30a6.ngrok-free.app/gusto/callback',
      auth0Domain: '',
      auth0ClientId: '',
      auth0Audience: '',
      logoutUrl: 'http://localhost:4040/logout',
      suggestLoginUser: true,
      showNavBar: true,
      stripeClientId:
        'pk_test_51IQh45IJhNbHfeWHDKEWWuYdUl74G1cZLLHxRDws6Te7waIrQZrla0jAGFc6s8H4PAGQC3Q6VMgADTWJvq53QqLM00AMyEO0H3',
      enableDocuments: true,
      enablePlacesAutocomplete: true,
      googleAPIKey: '',
      sentry: {
        enabled: false,
        env: 'development',
      },
      environment: 'local',
      hubspotAccountId: '22791610',
      launchDarkly: {
        clientId: '6177a09ec9dff82419892d28',
        SDKKey: 'sdk-2b4d88d1-365d-4b52-88dd-7b9afc38a96d',
      },
      adobePDFPreviewKey: '04688e002ad44ed0bb3cc10d45277467',
      churnDomain: 'formationscorp-dev',
      churnAppKey: '1!NdJ4pyKGx5YP19donFeInRArVwEP14rFwerGoyvQM1gt1030',
      churnSecretKey: 'formationsDev',
      churnSuccessLookUp:
        'https://formationscorp-dev.us2app.churnzero.net/SuccessCenterContactLookup',
      churnSuccessAuthCallback:
        'https://formationscorp-dev.us2app.churnzero.net/SuccessCenterCallback',
      userpilot: {
        apiKey: 'STG-NX-21a7e488',
      },
      hurdlr: {
        clientId:
          'k3TO4Li3K3gbH4clt9G7a3o5wvLa5we4KX0Q73X2O9dk18HM5XWmoyUW035N',
        environment: 'SANDBOX',
      },
      hellosign: {
        clientId: '45576b9235702af69bef71248069ba36',
        skipDomainVerification: true,
        debug: true,
      },
      stripe: {
        key: 'pk_test_szomDXlY5yCd7ShhxAUv6bej00PbCPNZVc',
        env: Environment.LOCALHOST,
        isTestMode: true,
      },
      squarespace: {
        clientId: 'bSBDezw8CXlZ1gUoxEveKOAEYWGOf9mY',
        redirectUrl: 'http://localhost:4040/squarespace/link',
      },
      enableProfitWell: false,
    };
  }

  const subdomain = host.split('.')[0];
  const byEnv =
    subdomain === 'app'
      ? configByEnv.production
      : (configByEnv as any)[subdomain] || {};
  return {
    apiUrl: `https://${host.replace('app.', 'api.')}/api/v1/`,
    apiBaseUrl: `https://${host.replace('app.', 'api.')}`,
    ...byEnv,
  };
};

const load = (): Config => {
  const configFromUrl = loadConfigFromUrl();

  return {
    apiUrl: process.env.REACT_APP_SERVER_URL || configFromUrl.apiUrl,
    apiBaseUrl:
      process.env.REACT_APP_SERVER_BASE_URL || configFromUrl.apiBaseUrl,
    apiPayrollUrl:
      process.env.REACT_APP_PAYROLL_SERVER_URL || configFromUrl.apiPayrollUrl,
    gustoClientId:
      process.env.REACT_APP_GUSTO_CLIENT_ID || configFromUrl.gustoClientId,
    gustoAuthUrl:
      process.env.REACT_APP_GUSTO_AUTH_URL || configFromUrl.gustoAuthUrl,
    gustoRedirectUri:
      process.env.REACT_APP_GUSTO_REDIRECT_URI ||
      configFromUrl.gustoRedirectUri,
    auth0Domain:
      process.env.REACT_APP_AUTH0_DOMAIN || configFromUrl.auth0Domain,
    auth0ClientId:
      process.env.REACT_APP_AUTH0_CLIENT_ID || configFromUrl.auth0ClientId,
    auth0Audience:
      process.env.REACT_APP_AUTH0_AUDIENCE || configFromUrl.auth0Audience,
    logoutUrl: process.env.REACT_APP_LOGOUT_URL || configFromUrl.logoutUrl,
    showNavBar: configFromUrl.showNavBar,
    suggestLoginUser: configFromUrl.suggestLoginUser,
    stripeClientId:
      process.env.REACT_APP_STRIPE_CLIENT_ID || configFromUrl.stripeClientId,
    enableDocuments: configFromUrl.enableDocuments,
    enablePlacesAutocomplete: configFromUrl.enablePlacesAutocomplete,
    googleAPIKey:
      process.env.REACT_APP_GOOGLE_API_KEY || configFromUrl.googleAPIKey,
    sentry: {
      enabled: configFromUrl.sentry.enabled,
      env: process.env.REACT_APP_SENTRY_ENVIRONMENT || configFromUrl.sentry.env,
    },
    environment: configFromUrl.environment,
    hubspotAccountId: configFromUrl.hubspotAccountId,
    launchDarkly: configFromUrl.launchDarkly,
    adobePDFPreviewKey: configFromUrl.adobePDFPreviewKey,
    churnDomain: configFromUrl.churnDomain,
    churnAppKey: configFromUrl.churnAppKey,
    churnSecretKey: configFromUrl.churnSecretKey,
    churnSuccessLookUp: configFromUrl.churnSuccessLookUp,
    churnSuccessAuthCallback: configFromUrl.churnSuccessAuthCallback,
    userpilot: configFromUrl.userpilot,
    hurdlr: configFromUrl.hurdlr,
    hellosign: configFromUrl.hellosign,
    stripe: configFromUrl.stripe,
    squarespace: configFromUrl.squarespace,
    enableProfitWell: configFromUrl.enableProfitWell,
  };
};

export const CONFIG: Config = load();
