import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMenuVisibleStore } from 'states';
import { SidebarMenuID } from 'enums';
import { Routes } from '../fnRoutes';

const accountPaths = [
  Routes.VENDORS,
  Routes.ACCOUNTING_ALL_TRANSACTIONS,
  Routes.ACCOUNTING_UNKNOWN_TRANSACTIONS,
  Routes.ACCOUNTING_ADD_TRANSACTION,
  Routes.BANK_ACCOUNTS,
  Routes.BANK_ACCOUNTS_CONNECT,
];

const payrollPaths = [Routes.PAYROLL_SETTINGS, Routes.PAYROLL];

// main hook to determine which menu should be opened
export const useMenuCollapsible = () => {
  const { openMenuId, setOpenMenuId } = useMenuVisibleStore();
  const { pathname } = useLocation();

  // check the path for intialize openMenuId
  useEffect(() => {
    // for accounting submenus
    if (
      accountPaths.includes(pathname) &&
      openMenuId !== SidebarMenuID.Account
    ) {
      setOpenMenuId(SidebarMenuID.Account);
    }
    // for payroll submenus
    if (
      payrollPaths.includes(pathname) &&
      openMenuId !== SidebarMenuID.Payroll
    ) {
      setOpenMenuId(SidebarMenuID.Payroll);
    }
  }, [pathname]);

  return [openMenuId, setOpenMenuId] as const;
};
