import { AuthService } from 'services';

export const Routes = {
  HOME: () =>
    AuthService.isAdmin() ? '/dashboard/accounts' : '/dashboard/welcome',
  ADMIN_HOME: '/dashboard/accounts',
  WORKNET: '/dashboard/worknet',
  SUPERADMIN_IRS_RATE: '/dashboard/irs-mileage-rate',
  TAX_BRACKET: '/dashboard/tax-bracket',
  ACTIVE_SURVEY: '/dashboard/active-survey',
  UPCOMING_EVENTS: '/dashboard/upcoming-events',
  HURDLR: '/dashboard/hurdlr',
  ACTIVE_TAX_YEARS: '/dashboard/active-tax-years',
  TAX_DEADLINE: '/dashboard/tax-deadline',
  STANDARD_DEDUCTION: '/dashboard/standard-deduction',
  TAX_LIABILITY: '/dashboard/tax-liability',
  CSM_MEETING_LINK: '/dashboard/csm-meeting-link',
  INDUSTRY_OPTIONS: '/dashboard/industry-options',
  VENDORS_IMPORT: '/dashboard/vendor-import',
  CUSTOMER_HOME: '/dashboard/welcome',
  ADMIN_ACCOUNT: '/dashboard/accounts/:id',
  LOGOUT: '/logout',

  LOGIN: '/login',
  SIGNUP: '/signup',
  POST_LOGIN: '/postlogin',
  POST_SIGNUP: '/postsignup',

  LOGIN1120S: '/login1120s',
  SIGNUP_1120S: '/signup1120s',
  POST_LOGIN_1120S: '/postlogin1120s',
  POST_SIGNUP_1120S: '/postsignup1120s',

  LOGINBIB: '/loginBIB',
  SIGNUP_BIB: '/signupBIB',
  POST_LOGIN_BIB: '/postloginBIB',
  POST_SIGNUP_BIB: '/postsignupBIB',

  TERMS_CONDITIONS: '/terms',
  WELCOME: '/dashboard/welcome',
  ACCOUNT: '/dashboard/account',
  MY_COMPANY: '/dashboard/company',
  TAX_LIABILITY_FORM: '/dashboard/tax-form',
  SUBSCRIPTION: '/subscription',
  DOCUMENTS: '/dashboard/documents',
  CALCULATOR: '/calculator',
  CALCULATOR_RESULT: '/calculator/result',
  MY_ACCOUNT: '/dashboard/account',
  MY_BANK: '/dashboard/banks',
  PROGRESS_TRACKER: '/progress-tracker',
  TRANSACTIONS: '/dashboard/transactions',
  ENTITY_SELECTION: '/onboarding/business-type',
  INCORPORATION_STATUS: '/onboarding/incorporation-status',
  GUSTO: '/dashboard/settings', // for admin only to integrate gusto
  GUSTO_INACCESSIBLE_COMPANIES: '/dashboard/settings/inaccessible-companies',
  ACCOUNT_YTD: '/dashboard/accounting/account-ytd',
  DOCUMENT_PREVIEW: '/dashboard/documents/preview',
  ACCOUNTING_UNKNOWN_TRANSACTIONS: '/dashboard/accounting/unknown-transactions',
  ACCOUNTING_ALL_TRANSACTIONS: '/dashboard/accounting/all-transactions',
  ACCOUNTING_ADD_TRANSACTION: '/dashboard/accounting/add-transaction',
  ACCOUNTING_REPORTS: '/dashboard/accounting/reports',
  ACCOUNTING_BUSINESS_MILES: '/dashboard/accounting/business-mileage',
  ACCOUNTING_BUSINESS_USE_OF_HOME: '/dashboard/accounting/business-use-of-home',
  PAYROLL: '/dashboard/payroll',
  PAYROLL_SETTINGS: '/dashboard/settings-and-requests',
  PAYROLL_SETTINGS_REQUEST_CHANGE:
    '/dashboard/settings-and-requests/request-change',
  PAYROLL_SETTINGS_REQUEST_PAYROLL:
    '/dashboard/settings-and-requests/request-payroll',
  PAYROLL_SETTINGS_REQUEST_PAYROLL_SUCCESS:
    '/dashboard/settings-and-requests/request-payroll-success',
  PAYROLL_ONBOARDING: '/dashboard/payroll-onboarding',
  XERO_CONNECTION: '/xero/connection',
  OIL: '/dashboard/oil',
  TAXES: '/dashboard/taxes',
  CHURN_ZERO_AUTHENTICATION: '/authenticate',
  TERMS_AND_CONDITIONS: '/terms-conditions',
  FIRST_EXPERIENCE: '/first-experience',
  FIRST_EXPERIENCE_STEP_1: '/first-experience/step-1',
  FIRST_EXPERIENCE_STEP_2: '/first-experience/step-2',
  FIRST_EXPERIENCE_STEP_3: '/first-experience/step-3',
  FIRST_EXPERIENCE_STEP_4: '/first-experience/step-4',
  FIRST_EXPERIENCE_STEP_5: '/first-experience/step-5',
  FIRST_EXPERIENCE_PLAN_SELECTION: '/first-experience/plan-selection',
  ACCOUNT_SETTING: '/dashboard/preferences/account-setting',
  MY_SUBSCRIPTION: '/dashboard/my-subscription',
  SPOOF: '/spoof',
  LANDING_1120S: '/landing-1120s',
  LANDING_BIB: '/landing-BIB',
  PAYROLL_ONBOARDING_FLOW: '/dashboard/accounts/:accountId/onboarding-flow',
  RUN_PAYROLL_FLOW: '/dashboard/accounts/:accountId/run-payroll',
  PAYROLL_DEDUCTIONS: '/dashboard/payroll-deductions/',
  BANK_ACCOUNTS: '/dashboard/accounting/bank-accounts',
  VENDORS: '/dashboard/accounting/vendors',
  BANK_ACCOUNTS_CONNECT: '/dashboard/accounting/bank-accounts/connect',
  ADMIN_ACCOUNT_YTD: '/dashboard/accounts/:accountId/account-ytd',
  PRICING_TABLE: '/dashboard/pricing-table',
  VERIFY_PAYMENT: '/dashboard/verify-payment',
  VERIFY_SQSP_PAYMENT: '/dashboard/verify-sqsp-payment',
  SETUP_PAGE: '/dashboard/setup-page',

  // new onboarding routes below:
  ONBOARDING: '/dashboard/onboarding',
  ONBOARDING_BUSINESS_FORMATION: '/dashboard/onboarding/business-formation',
  ONBOARDING_BUSINESS_FORMATION_STEP_1:
    '/dashboard/onboarding/business-formation/step-1',
  ONBOARDING_BUSINESS_FORMATION_STEP_2:
    '/dashboard/onboarding/business-formation/step-2',
  ONBOARDING_BUSINESS_FORMATION_STEP_3:
    '/dashboard/onboarding/business-formation/step-3',
  ONBOARDING_BUSINESS_FORMATION_STEP_4:
    '/dashboard/onboarding/business-formation/step-4',
  ONBOARDING_BANK_ACCOUNT: '/dashboard/onboarding/bank-account',
  ONBOARDING_BANK_ACCOUNT_STEP_1: '/dashboard/onboarding/bank-account/step-1',
  ONBOARDING_BANK_ACCOUNT_STEP_2: '/dashboard/onboarding/bank-account/step-2',
  ONBOARDING_BANK_ACCOUNT_STEP_3: '/dashboard/onboarding/bank-account/step-3',
  ONBOARDING_ACCOUNT_SETUP: '/dashboard/onboarding/account-setup',
  ONBOARDING_ACCOUNT_SETUP_STEP_1: '/dashboard/onboarding/account-setup/step-1',
  ONBOARDING_ACCOUNT_SETUP_STEP_2: '/dashboard/onboarding/account-setup/step-2',
  ONBOARDING_ACCOUNT_SETUP_STEP_3: '/dashboard/onboarding/account-setup/step-3',
  SQUARESPACE_DOMAIN_MANAGEMENT: '/dashboard/domains',
  SQUARESPACE_DOMAIN_SEARCH: '/dashboard/domains/search',
  SQUARESPACE_DOMAIN_CHECKOUT: '/dashboard/domains/checkout',
  SQUARESPACE_MANAGEMENT_LINK: '/squarespace/link',
};

export const generateLink = (
  link: string,
  params: { [key: string]: string } = {},
) =>
  Object.entries(params).reduce(
    (origin, [param, value]) => origin.replace(`:${param}`, value),
    link,
  );

export const getSpoofRoutes = () => {
  // @ts-ignore
  let spoofRoutes: {
    // @ts-ignore
    HOME: () => string;
    [key: string]: string;
  } = {
    HOME: () => '/spoof/dashboard/welcome',
  };
  Object.entries(Routes).forEach(([key, value]) => {
    if (typeof value === 'string') {
      spoofRoutes = {
        ...spoofRoutes,
        [key]: `/spoof${value}`,
      };
    }
  });
  return spoofRoutes;
};

export const SpoofRoutes = getSpoofRoutes();

export const NONE_AUTHENTICATED_ROUTES = [
  Routes.LANDING_1120S,
  Routes.LOGIN,
  Routes.LOGIN1120S,
  Routes.LOGINBIB,
  Routes.LOGOUT,
  Routes.POST_LOGIN,
  Routes.POST_SIGNUP,
  Routes.POST_LOGIN_1120S,
  Routes.POST_SIGNUP_1120S,
  Routes.POST_LOGIN_BIB,
  Routes.POST_SIGNUP_BIB,
  Routes.CALCULATOR,
  Routes.CALCULATOR_RESULT,
  '/',
];
export const isAllowedNoneAuthenticatedRoute = (pathname: string) =>
  NONE_AUTHENTICATED_ROUTES.includes(pathname);

export const allowRedirectRoute = [
  Routes.DOCUMENT_PREVIEW,
  Routes.WELCOME,
  Routes.DOCUMENTS,
  Routes.XERO_CONNECTION,
  Routes.ADMIN_HOME,
  Routes.TAX_LIABILITY_FORM,
  Routes.CHURN_ZERO_AUTHENTICATION,
  Routes.ADMIN_ACCOUNT,
  Routes.ACCOUNTING_UNKNOWN_TRANSACTIONS,
  Routes.ACCOUNTING_BUSINESS_USE_OF_HOME,
  Routes.OIL,
  Routes.TAXES,
  Routes.PAYROLL,
  Routes.MY_COMPANY,
  Routes.MY_SUBSCRIPTION,
  Routes.SQUARESPACE_DOMAIN_CHECKOUT,
  Routes.SQUARESPACE_MANAGEMENT_LINK,
];

export const isValidRedirectUrl = (encodedcUrl: string) => {
  const url: string = decodeURIComponent(encodedcUrl);
  return allowRedirectRoute.some((allowedUrl) => url.includes(allowedUrl));
};
