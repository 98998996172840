import { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  TextField,
} from '@mui/material';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { showSuccessToast } from 'components/toast/showToast';
import { queryClient } from 'states/reactQueryClient';
import { DocumentYear } from 'services/documentTypes';
import {
  useApproveDocument,
  useDocumentCategories,
  useRejectDocument,
  useUpdateDocument,
} from 'hooks/api/useDocuments';
import { EDocumentStatus } from 'hooks/dataFormatters/useDocumentsTableData';
import { CategorizeSelectors } from 'components/documentsV1';
import {
  defaultDocumentCategory,
  IDefaultDocumentCategory,
} from 'components/documentsV1/DocumentForm';
import { useDocumentDataOptions } from 'components/documentsV1/helper';
import { IFormationsPreviewDocument } from './FilePreviewComponent';

interface Props {
  accountId: string;
  enableAction?: boolean;
  file: IFormationsPreviewDocument;
  actionCallBack?: () => void;
}

const maxCommentLength = 300;

export const AdminFileAction = (props: Props) => {
  const { accountId, enableAction = false, file, actionCallBack } = props;
  const [fileState, setFileState] = useState<any>(EDocumentStatus.Submitted);
  const [comment, setComment] = useState('');
  const [value, setValue] = useState<IDefaultDocumentCategory>(
    defaultDocumentCategory,
  );
  const { categories: categoriesData } = useDocumentCategories();
  const {
    yearOptions,
    departmentOptions,
    categoryOptions,
    subCategoryOptions,
  } = useDocumentDataOptions(value, categoriesData);

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries(['documents', 'accountId', accountId]);
    await queryClient.invalidateQueries(['documents', file.id]);
  };

  const { mutateAsync: updateDocument, isLoading: isDocumentUpdating } =
    useUpdateDocument({
      onSuccess: () => {
        invalidateQueries();
      },
    });

  const { mutateAsync: approveDocument, isLoading: isDocumentApproving } =
    useApproveDocument({
      onSuccess: () => {
        invalidateQueries();
      },
    });

  const { mutateAsync: rejectDocument, isLoading: isDocumentRejecting } =
    useRejectDocument({
      onSuccess: () => {
        showSuccessToast('Document rejected successfully');
        invalidateQueries();
      },
    });

  const isLoading =
    isDocumentApproving || isDocumentUpdating || isDocumentRejecting;

  const handleChange = (name: string, v: string | DocumentYear) => {
    let newValue = {};

    if (name === 'department') {
      newValue = {
        department: v,
        category: '',
        subcategory: '',
      };
    } else if (name === 'category') {
      newValue = {
        category: v,
        subcategory: '',
      };
    } else {
      newValue = {
        [name]: v,
      };
    }
    setValue({ ...value, ...newValue });
  };

  const disableSave = useMemo(() => {
    switch (fileState) {
      case EDocumentStatus.Submitted:
      case EDocumentStatus.Approved:
        return (
          !value.year ||
          !value.department ||
          !value.category ||
          (!value.subcategory && subCategoryOptions.length > 0)
        );
      case EDocumentStatus.Rejected:
        return comment.length <= 0;
      default:
        return false;
    }
  }, [value, fileState, comment, subCategoryOptions]);

  useEffect(() => {
    if (file.id) {
      setFileState(file.status);
      setValue({
        year: file.forYear || 'Permanent',
        department: file.department ?? '',
        category: file.category ?? '',
        subcategory: file.subcategory ?? '',
        visibleToCustomer: file.visibleToCustomer ?? false,
        isVisibilityEditable: file.isVisibilityEditable ?? false,
        emailTemplateId: '',
      });
      setComment(file.statusReason ?? '');
    }
  }, [file.id]);

  const isStatusUpdated: boolean = useMemo(
    () => file?.status !== EDocumentStatus.Submitted,
    [file],
  );

  const { saveButtonText, showRejectComment, showCategory, showSaveButton } =
    useMemo(() => {
      const res = {
        saveButtonText: 'Recategorize',
        showRejectComment: false,
        showCategory: false,
        showSaveButton: false,
      };
      if (!isStatusUpdated && enableAction) {
        res.saveButtonText = 'Save';
      }

      switch (fileState) {
        case EDocumentStatus.Rejected:
          if (!isStatusUpdated) {
            res.showSaveButton = true;
          }
          res.showRejectComment = true;
          break;
        case EDocumentStatus.Approved:
        case EDocumentStatus.Submitted:
        default:
          res.showCategory = true;
          res.showSaveButton = true;
          break;
      }
      return res;
    }, [fileState, isStatusUpdated, enableAction]);

  const fileStatusText = useMemo(() => {
    if (file?.isVisibilityEditable) {
      // file uploaded by admin
      return 'Admin uploaded';
    }
    switch (file?.status) {
      case EDocumentStatus.Approved:
        return 'Admin approved';
      case EDocumentStatus.Rejected:
        return 'Admin rejected';
      case EDocumentStatus.Submitted:
      default:
        return 'User uploaded';
    }
  }, [file]);

  const handleDocumentUpdate = async () => {
    const { department, category, subcategory } = value;
    const documentCategoryId = categoriesData.find(
      (item) =>
        item.department === department &&
        item.category === category &&
        item.subcategory === subcategory,
    )?.id;
    switch (fileState) {
      case EDocumentStatus.Approved:
        if (file.status !== EDocumentStatus.Approved) {
          await approveDocument({
            id: file.id,
          });
        }
        await updateDocument({
          id: file.id,
          form: {
            status: EDocumentStatus.Approved,
            year: value.year || 'Permanent',
            companyId: file.companyId || '',
            documentCategoryId: documentCategoryId ?? '',
          },
        });
        break;
      case EDocumentStatus.Rejected:
        await rejectDocument({
          id: file.id,
          form: {
            reason: comment,
          },
        });
        break;
      case EDocumentStatus.Submitted:
        await updateDocument({
          id: file.id,
          form: {
            status: EDocumentStatus.Approved,
            year: value.year || 'Permanent',
            companyId: file.companyId || '',
            documentCategoryId: documentCategoryId ?? '',
          },
        });
        break;
      default:
        break;
    }
    if (actionCallBack) {
      actionCallBack();
    }
  };

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{ paddingLeft: 2 }}
    >
      {enableAction ? (
        <FormControl
          component="fieldset"
          disabled={isStatusUpdated}
          sx={{ mb: 2 }}
        >
          <RadioGroup
            aria-label="option"
            name="options"
            value={fileState}
            onChange={(e) => setFileState(e.target.value)}
            defaultValue={EDocumentStatus.Submitted}
          >
            <FormControlLabel
              value={EDocumentStatus.Submitted}
              control={<Radio />}
              label="Waiting"
            />
            <FormControlLabel
              value={EDocumentStatus.Approved}
              control={<Radio />}
              label="Approve"
            />
            <FormControlLabel
              value={EDocumentStatus.Rejected}
              control={<Radio />}
              label="Reject"
            />
          </RadioGroup>
        </FormControl>
      ) : (
        <Typography variant="h6" sx={{ mb: 2 }}>
          {fileStatusText}
        </Typography>
      )}
      {showRejectComment && (
        <TextField
          multiline
          fullWidth
          required
          autoFocus
          minRows={6}
          maxRows={6}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          label="Comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx={{ mb: 2 }}
          disabled={isStatusUpdated}
          inputProps={{ maxLength: maxCommentLength }}
          data-testid="field-reject-comment"
        />
      )}
      {showCategory && (
        <CategorizeSelectors
          options={{
            yearOptions,
            departmentOptions,
            categoryOptions,
            subCategoryOptions,
          }}
          handleChange={handleChange}
          value={value}
        />
      )}
      {showSaveButton && (
        <FormationsPrimaryButton
          size="large"
          onClick={handleDocumentUpdate}
          disabled={disableSave || isLoading}
          data-testid="save-btn"
        >
          {saveButtonText}
        </FormationsPrimaryButton>
      )}
    </Grid>
  );
};
