export enum ITodoItemStatus {
  'Open' = 'Open',
  'Completed' = 'Completed',
}

export enum ITodoItemType {
  'AutoMileageExpense' = 'AutoMileageExpense',
  'GrossIncomeForecast' = 'GrossIncomeForecast',
  'UnknownTransactions' = 'UnknownTransactions',
  'PayrollOnboarding' = 'PayrollOnboarding',
  'BusinessUseOfHome' = 'BusinessUseOfHome',
  'CheckInSurvey' = 'CheckInSurvey',
  'OilList' = 'OilList',
  'BankConnectionError' = 'BankConnectionError',
  'VendorW9Upload' = 'VendorW9Upload',
}
export enum ITodoItemConfirmationType {
  'System' = 'System',
  'Person' = 'Person',
}
export enum ITodoItemImportance {
  'Critical' = 'Critical',
  'Ongoing' = 'Ongoing',
  'Recommended' = 'Recommended',
}

export interface ITodoItem {
  id: string;
  title: string;
  action: string;
  link: string;
  dueDate: string;
  createdBy: 'System' | string;
  createdDate: string;
  status: ITodoItemStatus;
  type: ITodoItemType;
  confirmationType: ITodoItemConfirmationType;
  importance: ITodoItemImportance;
  accountId?: string;
  completedAt?: string;
  metadata?: any;
}

export interface IBusinessMileTodoItem extends ITodoItem {
  metadata: {
    month: number;
    year: number;
  };
}
