import { FormationsTableDocument } from 'components/common/tables/DocumentsTable';
import { IFormationsPreviewDocument } from 'components/FilePreview/FilePreviewComponent/FilePreviewComponent';
import { FormationsDocument } from 'hooks/dataFormatters/useDocumentsTableData';
import { DocumentCategory } from 'services/documentTypes';

export const findCategoryByName = ({
  categories,
  name,
  subcategory,
  department,
}: {
  categories: DocumentCategory[] | undefined;
  name: string;
  subcategory: string;
  department: string;
}) => {
  if (
    !categories ||
    !Array.isArray(categories) ||
    categories.length <= 0 ||
    !name ||
    typeof name !== 'string'
  ) {
    return null;
  }

  const category = categories.find(
    (f) =>
      f.category === name &&
      f.subcategory === subcategory &&
      f.department === department,
  );
  return category?.id;
};

export const getDocumentAvailableForCategory = ({
  documents,
  category,
  subcategory,
  department,
}: {
  documents: FormationsDocument[];
  category: string;
  subcategory: string;
  department: string;
}) =>
  documents.filter(
    (d: any) =>
      d.documentCategory &&
      d.documentCategory.category === category &&
      d.documentCategory.subcategory === subcategory &&
      d.documentCategory.department === department,
  );

export const isDocumentAvailableForCategory = ({
  documents,
  category,
  subcategory,
  department,
}: {
  documents: FormationsDocument[];
  category: string;
  subcategory: string;
  department: string;
}): boolean => {
  const documentsForCategory = getDocumentAvailableForCategory({
    documents,
    category,
    subcategory,
    department,
  });

  return documentsForCategory.length > 0;
};

export const downloadFileBlob = (blob: Blob, title: string): void => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', title);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link?.parentNode?.removeChild(link);
};

export const castFTDToFormationsPreviewDocument = (
  document: FormationsTableDocument,
): IFormationsPreviewDocument => ({
  id: document.id,
  title: document.title,
  forYear: document.forYear,
  companyId: document.companyId,
  department: document?.department,
  category: document.category,
  subcategory: document.subcategory,
  visibleToCustomer: document.visibleToCustomer,
  isVisibilityEditable: document.isVisibilityEditable,
  status: document.status,
  statusReason: document.statusReason,
  role: document.role,
  metadata: document.metadata,
  accountId: document.accountId,
  uploader: {
    accountId: document.uploader.accountId,
  },
  customerReadCount: document.customerReadCount,
});

export const castFDToFormationsPreviewDocument = (
  document: FormationsDocument,
): IFormationsPreviewDocument => ({
  id: document.id,
  title: document.title,
  forYear: document.forYear,
  companyId: document.companyId,
  department: document.documentCategory?.department,
  category: document.documentCategory?.category,
  subcategory: document.documentCategory?.subcategory,
  visibleToCustomer: document.visibleToCustomer,
  isVisibilityEditable: document.uploader.role === 'Admin',
  status: document.status,
  statusReason: document.statusReason,
  role: document.uploader.role,
  metadata: document.metadata,
  accountId: document.accountId,
  uploader: {
    accountId: document.uploader.accountId,
  },
  customerReadCount: document.customerReadCount,
});

export const castListOfFormationDocument = (
  documentList: FormationsDocument[],
): IFormationsPreviewDocument[] =>
  documentList.map(castFDToFormationsPreviewDocument);

export const castListOfFormationTableDocument = (
  documentList: FormationsTableDocument[],
): IFormationsPreviewDocument[] =>
  documentList.map(castFTDToFormationsPreviewDocument);

export const caseFDToFTD = (
  document: FormationsDocument,
): FormationsTableDocument => ({
  id: document.id,
  title: document.title,
  forYear: document.forYear,
  companyId: document.companyId,
  department: document.documentCategory?.department,
  category: document.documentCategory?.category,
  subcategory: document.documentCategory?.subcategory,
  visibleToCustomer: document.visibleToCustomer,
  isVisibilityEditable: document.uploader.role === 'Admin',
  status: document.status,
  statusReason: document.statusReason ?? '',
  role: document.uploader.role,
  metadata: document.metadata,
  accountId: document.accountId,
  hasNotes: document.hasNotes, // Add the missing property hasNotes
  adminUnread: document.adminUnread, // Add the missing property adminUnread
  customerUnread: document.customerUnread, // Add the missing property customerUnread
  uploader: {
    accountId: document.uploader.accountId,
  },
  customerReadCount: document.customerReadCount,
});

export const downloadRemotePDFFile = async (url: string, fileName: string) => {
  await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
    },
  }).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      const alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = fileName;
      alink.click();
    });
  });
};
