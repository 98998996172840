import React, { useCallback, useMemo, useState } from 'react';
import {
  TableBody,
  TableContainer,
  TableRow,
  Paper,
  Tooltip,
  Grid,
} from '@mui/material';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';
import { FormationsTable } from 'components/common/FormationsTable';
import useLoading from 'hooks/useLoading';
import {
  transformDocumentQueryParams,
  useCurrentUserRole,
  useDocumentQuery,
  useDocumentsByAccount,
} from 'hooks/api';
import { useDocumentsTableData } from 'hooks/dataFormatters';
import {
  castFTDToFormationsPreviewDocument,
  castListOfFormationTableDocument,
  downloadFileBlob,
} from 'helpers/documents';
import { downloadDocument } from 'services/document';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import {
  CHURN_ZERO_EVENT_NAMES,
  defaultTablePageSize,
  defaultTableRowsPerPageOptions,
} from 'constants/common';
import { showToastOnError } from 'components/toast/showToast';
import { SearchBar } from 'components/common/SearchBar';
import {
  DocumentHeader,
  adminDocumentsHeaders,
  documentsHeaders,
} from './header/DocumentHeader';
import { FormationsTableDocument } from '../common/tables/DocumentsTable';
import { FilePreviewDialog } from '../FilePreview';
import { IFormationsPreviewDocument } from '../FilePreview/FilePreviewComponent/FilePreviewComponent';
import { DocumentTableCell } from './tableCell/DocumentTableCell';
import { EmptyTable } from './tableCell/EmptyTable';
import {
  DOCUMENT_HUB_NOTE_FLAG,
  DOCUMENT_HUB_STATUS_FLAG,
  useFeatureFlag,
} from '../../hooks/useFeatureFlag';

const DocumentsTableContainer = styled('div')(() => ({
  flex: '1 1 auto',
  display: 'flex',
  flexFlow: 'column',
  minHeight: 100,
  width: '100%',
}));

const DownloadButton = styled(FormationsTertiaryButton)(() => ({
  width: 150,
}));

const ButtonPlaceHolder = styled(Grid)(() => ({
  height: 45,
  width: 150,
}));

const DocumentTableRow = styled(TableRow)(() => ({
  position: 'relative',
  '&:hover': {
    '.hoverCheckbox': {
      display: 'block',
    },
    '.activeCheckbox': {
      display: 'none',
    },
    '.activeIcon': {
      display: 'none',
    },
  },
  '.hoverCheckbox': {
    display: 'none',
  },
}));

const DocumentsSearchBar = () => {
  const [query, setQuery] = useDocumentQuery();
  const onSearch = useCallback((value: string) => {
    setQuery({ keyword: value || undefined, page: 1 });
  }, []);

  return (
    <SearchBar
      query={query.keyword}
      onSearch={onSearch}
      placeHolderText="Enter Document Name"
    />
  );
};

interface Props {
  accountId: string;
}

export type SelectedFile = {
  id: string;
  title: string;
};

export const DocumentsTable = ({ accountId }: Props) => {
  const [query, setQuery] = useDocumentQuery();
  const [selectedFile, setSelectedFile] =
    useState<IFormationsPreviewDocument>();
  const handleClosePreviewDialog = () => setSelectedFile(undefined);
  const {
    documents,
    pageInfo: { totalCount },
    isLoading: isLoadingDocuments,
  } = useDocumentsByAccount(accountId, transformDocumentQueryParams(query), {
    enabled: !!accountId,
  });

  const { isAdmin } = useCurrentUserRole();
  const [selected, setSelected] = React.useState<readonly SelectedFile[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const showNoteColumn = useFeatureFlag(DOCUMENT_HUB_NOTE_FLAG);
  const showStatusColumn = useFeatureFlag(DOCUMENT_HUB_STATUS_FLAG);
  const headers = useMemo(() => {
    if (isAdmin) {
      return [...adminDocumentsHeaders];
    }
    let res = [...documentsHeaders];
    if (!showStatusColumn) {
      res = res.filter((h) => h.key !== 'status');
    }
    if (!showNoteColumn) {
      res = res.filter((h) => h.key !== 'hasNotes');
    }
    return res;
  }, [showNoteColumn, showStatusColumn, isAdmin]);

  const data = useDocumentsTableData(documents);

  const loading = isLoadingDocuments || isDownloading;

  const onDownload = async () => {
    setIsDownloading(true);
    await Promise.all(
      selected.map(async (sFile) => {
        try {
          const blob = await downloadDocument(sFile.id);
          downloadFileBlob(blob, sFile.title);
          sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.DOCUMENT_DOWNLOAD);
        } catch (e) {
          showToastOnError(e);
        }
      }),
    );
    setIsDownloading(false);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => ({
        id: n.id,
        title: n.title,
      }));
      const uniqueFiles = [...newSelected, ...selected].filter(
        (file, index, self) =>
          index === self.findIndex((f) => f.id === file.id),
      );
      setSelected(uniqueFiles);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: string) => {
    const sFile = data.find((d) => d.id === id);
    const selectedIndex = selected.findIndex((s) => s.id === id);
    let newSelected: readonly SelectedFile[] = [];
    if (sFile) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, sFile);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    }
  };

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setQuery({ size: parseInt(event.target.value, 10), page: 1 });
    },
    [],
  );

  const handleTablePageChange = useCallback(
    (
      _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      pageData: number,
    ) => {
      setQuery({ page: pageData + 1 });
    },
    [],
  );

  const handleFileNameClick = (file: FormationsTableDocument) => {
    setSelectedFile(castFTDToFormationsPreviewDocument(file));
  };

  return (
    <DocumentsTableContainer>
      {useLoading(loading)}
      {selected.length > 0 ? (
        <Tooltip title="Download">
          <span style={{ width: 'max-content' }}>
            <DownloadButton size="large" onClick={onDownload}>
              Download
            </DownloadButton>
          </span>
        </Tooltip>
      ) : (
        <ButtonPlaceHolder />
      )}
      <Grid sx={{ width: 400 }}>
        <DocumentsSearchBar />
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ width: '100%', overflow: 'auto' }}
      >
        <FormationsTable
          stickyHeader
          aria-label="document-table"
          data-testid="table-documents"
          id="document-table"
          sx={{
            minWidth: '320px',
            tableLayout: isAdmin ? 'fixed' : 'auto',
          }}
        >
          <DocumentHeader
            onSelectAllClick={handleSelectAllClick}
            selectedRows={selected}
            currentRows={data}
          />
          <TableBody>
            {(!data || data.length === 0) && !loading ? <EmptyTable /> : null}
            {data?.map((row: FormationsTableDocument) => (
              <DocumentTableRow
                key={row.id}
                hover
                data-testid={`table-row-document-${row.id}`}
                selected={selected.findIndex((s) => s.id === row.id) !== -1}
              >
                {headers.map((item) => (
                  <DocumentTableCell
                    accountId={accountId}
                    key={item.key}
                    item={item}
                    document={row}
                    openDocumentPreview={handleFileNameClick}
                    checked={selected.findIndex((s) => s.id === row.id) !== -1}
                    onCheckboxClick={() => handleClick(row.id)}
                  />
                ))}
              </DocumentTableRow>
            ))}
          </TableBody>
        </FormationsTable>
      </TableContainer>

      {data.length !== 0 && (
        <TablePagination
          component="div"
          color="primary"
          sx={{
            mt: 3,
            mb: 4,
            '.MuiTablePagination-select': {
              border: '1px solid',
              borderColor: 'others.stroke',
              borderRadius: '4px',
              pr: 4,
            },
          }}
          page={query?.page ? query.page - 1 : 0}
          count={totalCount}
          onPageChange={handleTablePageChange}
          rowsPerPage={query?.size || defaultTablePageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={defaultTableRowsPerPageOptions}
        />
      )}
      {selectedFile && (
        <FilePreviewDialog
          accountId={accountId}
          open
          file={selectedFile}
          files={castListOfFormationTableDocument(data)}
          onClose={handleClosePreviewDialog}
        />
      )}
    </DocumentsTableContainer>
  );
};
