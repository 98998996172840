import { IDocumentCategory } from '../components/FilePreview/FilePreviewComponent';

export const MAX_DOCUMENT_SIZE_MB = 50;
export const MAX_DOCUMENT_SIZE_BYTES = MAX_DOCUMENT_SIZE_MB * 1024 * 1024;

export const KYC_DOCUMENT: IDocumentCategory = {
  category: 'Miscellaneous',
  subcategory: 'Biographical Information',
  department: 'Profile & Account',
};

export const BOOK_OF_BUSINESS: IDocumentCategory = {
  category: 'Reports',
  subcategory: 'Monthly Financial Report',
  department: 'Accounting',
};

export const ACCOUNT_YTD: IDocumentCategory = {
  category: 'Financials',
  subcategory: 'Miscellaneous',
  department: 'Accounting',
};

export const ACCOUNT_YTD_PL_STATEMENT: IDocumentCategory = {
  category: 'Financials',
  subcategory: 'Profit and Loss Statement',
  department: 'Accounting',
};

export const W9_FORM: IDocumentCategory = {
  category: 'Organizational Docs',
  subcategory: 'W9s',
  department: 'Profile & Account',
};

export const PT_ID_PROOF: IDocumentCategory = {
  category: 'Miscellaneous',
  subcategory: 'Biographical Information',
  department: 'Profile & Account',
};

export const PT_TAX_STATEMENT: IDocumentCategory = {
  category: 'Business',
  subcategory: 'Tax Return',
  department: 'Tax',
};

export const PT_DOC_INCORPORATION: IDocumentCategory = {
  category: 'Organizational Docs',
  subcategory: 'Miscellaneous',
  department: 'Profile & Account',
};

export const PT_SIGNING_DOCUMENT: IDocumentCategory = {
  category: 'Organizational Docs',
  subcategory: 'Signed Agreement',
  department: 'Profile & Account',
};

export const REASONABLE_COMPENSATION_STUDY: IDocumentCategory = {
  department: 'Payroll',
  category: 'Plans',
  subcategory: 'Reasonable comp',
};

export const PAYROLL_PLAN: IDocumentCategory = {
  department: 'Payroll',
  category: 'Plans',
  subcategory: 'Payroll Plan',
};

export const SIGNED_AGREEMENT: IDocumentCategory = {
  department: 'Profile & Account',
  category: 'Organizational Docs',
  subcategory: 'Signed Agreement',
};

export enum EDocumentDepartment {
  'Tax' = 'Tax',
  'Profile & Account' = 'Profile & Account',
  'Accounting' = 'Accounting',
  'Payroll' = 'Payroll',
}

export const VENDOR_W9_DOCUMENT: IDocumentCategory = {
  department: EDocumentDepartment.Accounting,
  category: 'Vendors',
  subcategory: 'W9s',
};

export const VENDOR_1099_DOCUMENT: IDocumentCategory = {
  department: EDocumentDepartment.Accounting,
  category: 'Reports',
  subcategory: '1099',
};

export const W9_PDF_URL =
  'https://formations-public-docs.s3.us-west-2.amazonaws.com/fw9.pdf';
