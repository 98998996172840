import React, { useEffect, useMemo, useState } from 'react';
import { ArrowForwardIcon, ArrowBackIcon } from 'components/common/Icon';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import { Loading } from 'components/common';
import { EDocumentStatus } from 'hooks/dataFormatters/useDocumentsTableData';
import { useDownloadDocument } from 'hooks/api/useDocuments';
import { DocumentYear, IDocumentMetadata } from 'services/documentTypes';
import { FilePreview } from './FilePreview';
import { ClickableArrow } from './ClickableArrow';
import { AdminFileAction } from './AdminFileAction';
import { useCurrentUserRole } from '../../../hooks/api';
import { DocumentNoteComponent } from './DocumentNoteComponent';

const useStyles = makeStyles((theme) => ({
  fullHeightContainer: {
    height: '100%',
  },
  previewContainer: {
    height: '100%',
  },
  fileContainer: {
    boxShadow: theme.shadows[4],
  },
}));

export interface IDocumentBlob {
  unsupportedType: boolean;
  fileType: string;
  fileBlob: any;
}

export interface IDocumentCategory {
  department: string;
  category: string;
  subcategory: string;
}

export interface IFormationsPreviewDocument {
  id: string;
  title: string;
  forYear: DocumentYear;
  companyId: string;
  department?: string;
  category?: string;
  subcategory?: string;
  visibleToCustomer?: boolean;
  isVisibilityEditable?: boolean;
  status: EDocumentStatus;
  statusReason?: string;
  role?: string;
  metadata: IDocumentMetadata;
  accountId: string;
  uploader: {
    accountId: string;
  };
  customerReadCount: number;
}

interface Props {
  accountId: string;
  enableAction?: boolean;
  file: IFormationsPreviewDocument;
  files: IFormationsPreviewDocument[];
  actionCallBack?: () => void;
  showSideArrow?: boolean;
  onSideArrowClick?: (newIndex: number) => void;
  showDocumentNotes?: boolean;
}

const initDocumentBlobState: IDocumentBlob = {
  unsupportedType: false,
  fileType: '',
  fileBlob: undefined,
};

export const FilePreviewComponent: React.FC<Props> = ({
  accountId,
  enableAction = false,
  file,
  files,
  actionCallBack,
  showSideArrow = false,
  onSideArrowClick,
  showDocumentNotes = true,
}) => {
  const { isAdmin } = useCurrentUserRole();
  const classes = useStyles();
  const [documentBlob, setDocumentBlob] = useState<IDocumentBlob>(
    initDocumentBlobState,
  );

  const { blob: downloadDocumentBlob, isLoading } = useDownloadDocument(
    file.id,
  );

  useEffect(() => {
    if (downloadDocumentBlob) {
      const docType = downloadDocumentBlob?.type;
      const newFile = {
        ...initDocumentBlobState,
        fileBlob: downloadDocumentBlob,
      };
      switch (docType) {
        case 'application/pdf':
          newFile.fileType = 'pdf';
          newFile.unsupportedType = false;
          break;
        case 'image/png':
        case 'image/jpeg':
        case 'image/jpg':
          newFile.fileType = 'image';
          newFile.unsupportedType = false;
          break;
        case 'video/mp4':
          newFile.fileType = 'mp4';
          newFile.unsupportedType = false;
          break;
        default:
          newFile.unsupportedType = true;
          break;
      }
      setDocumentBlob(newFile);
    }
  }, [downloadDocumentBlob]);

  const { unsupportedType, fileType, fileBlob } = documentBlob;

  const currentFileIndex: number = useMemo(() => {
    if (file && files) {
      return files.findIndex((f) => f.id === file.id);
    }
    return 0;
  }, [file, files]);

  const totalFiles: number = useMemo(() => files.length, [files]);

  const resetStates = () => {
    setDocumentBlob(initDocumentBlobState);
  };

  const prevFile = () => {
    resetStates();
    if (onSideArrowClick && currentFileIndex > 0) {
      onSideArrowClick(currentFileIndex - 1);
    }
  };

  const nextFile = () => {
    resetStates();
    if (onSideArrowClick && currentFileIndex < files.length - 1) {
      onSideArrowClick(currentFileIndex + 1);
    }
  };

  const mainGridSize = showSideArrow ? 10 : 12;
  const adminGridSize = 8;
  const fileContainer = unsupportedType ? undefined : classes.fileContainer;
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          className={classes.previewContainer}
        >
          {showSideArrow && (
            <ClickableArrow
              onClick={prevFile}
              disabled={currentFileIndex <= 0}
              icon={<ArrowBackIcon />}
            />
          )}
          <Grid
            container
            item
            justifyContent="center"
            alignItems="flex-start"
            xs={mainGridSize}
            className={classes.fullHeightContainer}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ flex: 1, flexGrow: 1 }}
              className={classes.fullHeightContainer}
            >
              <Grid item xs={adminGridSize} className={fileContainer}>
                <FilePreview
                  file={file}
                  fileBlob={fileBlob}
                  fileType={fileType}
                  unsupportedType={unsupportedType}
                />
              </Grid>
              <Grid item xs={4}>
                {isAdmin && (
                  <AdminFileAction
                    accountId={accountId}
                    enableAction={enableAction}
                    file={file}
                    actionCallBack={actionCallBack}
                  />
                )}
                {showDocumentNotes && <DocumentNoteComponent document={file} />}
              </Grid>
            </Grid>
          </Grid>
          {showSideArrow && (
            <ClickableArrow
              onClick={nextFile}
              disabled={currentFileIndex >= totalFiles - 1}
              icon={<ArrowForwardIcon />}
            />
          )}
        </Grid>
      )}
    </>
  );
};
