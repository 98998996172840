import DialogContent from '@mui/material/DialogContent';
import {
  FormationsFormFields,
  IFormField,
  renderField,
} from 'components/common/FormationsForm2';
import { useForm } from 'react-hook-form';
import { Box, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import { showErrorToast } from 'components/toast/showToast';
import i18n from 'translations/i18n';
import {
  useAccount,
  useCreateDocumentNotes,
  useCurrentUserRole,
  useDocumentNotes,
  useMarkNotesAsRead,
  useUser,
} from 'hooks/api';
import { queryClient } from 'states/reactQueryClient';
import { getPrettyDateTimeFromNow } from 'helpers/dateTimeFormat';
import { IDocumentNote } from 'services/documentTypes';
import { DOCUMENT_NOTE_MAX_LENGTH } from 'constants/common';
import { documentNoteAdminSchema, documentNoteSchema } from 'constants/schemas';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { SendIcon } from 'components/common/Icon';
import { IFormationsPreviewDocument } from './FilePreviewComponent';

interface IDocumentNoteComponent {
  document: IFormationsPreviewDocument;
}

const DocumentNoteItem = ({
  note,
  documentAccountId,
}: {
  note: IDocumentNote;
  documentAccountId: string;
}) => {
  const { account } = useAccount(documentAccountId);
  const { user } = useUser(account?.ownerId);
  // document accountId will always be the customer's accountId
  // if creatorId not the same as accountId, it is posted by admin
  const isAdminNote = documentAccountId !== note.creatorId;
  const noteCreatorName = useMemo(() => {
    if (isAdminNote) {
      return 'Formations';
    }
    // note is created by customer
    if (user?.preferredName) {
      return user?.preferredName;
    }
    if (user?.name?.first) {
      return user?.name?.first;
    }
    return 'Customer';
  }, [
    documentAccountId,
    note.creatorId,
    user?.preferredName,
    user?.name?.first,
  ]);
  return (
    <Grid item key={note.id} sx={{ p: 3 }} data-testid="oil-item-note-item">
      <Grid
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        xs={12}
        sx={{ mb: 3 }}
      >
        <Typography variant="body2B">{noteCreatorName}</Typography>
        <Typography variant="tooltip" sx={{ color: 'text.hint' }}>
          {getPrettyDateTimeFromNow(note.createdAt)}
        </Typography>
      </Grid>
      {isAdminNote && (
        <Typography
          variant="body3"
          sx={{ mb: 3 }}
          dangerouslySetInnerHTML={{ __html: note.note }}
        />
      )}
      {!isAdminNote && (
        <Typography variant="body3" sx={{ whiteSpace: 'pre-line', mb: 3 }}>
          {note.note}
        </Typography>
      )}
    </Grid>
  );
};
export const DocumentNoteComponent = ({ document }: IDocumentNoteComponent) => {
  const { account } = useAccount(document.accountId);
  const { isLoginUserAdmin } = useCurrentUserRole();
  const { id: documentId, accountId: documentAccountId } = document;
  const noteContainerBottomRef = useRef<HTMLDivElement>(null);
  const { notes } = useDocumentNotes(documentId);
  const { mutateAsync: createDocumentNote } = useCreateDocumentNotes({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['document-notes', documentId]);
    },
  });

  const { mutateAsync: markNoteAsRead } = useMarkNotesAsRead({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['document-notes', documentId]);
    },
  });

  const readNote = async () => {
    try {
      await markNoteAsRead(documentId);
      await queryClient.invalidateQueries([
        'documents',
        'accountId',
        documentAccountId,
      ]);
    } catch (e: unknown) {
      if (e instanceof Error) {
        showErrorToast(e.message);
      } else {
        showErrorToast('An error occurred while updating transaction note.');
      }
    }
  };

  const executeScroll = useCallback(() => {
    noteContainerBottomRef?.current?.scrollIntoView?.({
      block: 'end',
    });
  }, []);

  const documentNote: IFormField = {
    name: 'documentNote',
    type: isLoginUserAdmin
      ? FormationsFormFields.RichText
      : FormationsFormFields.TextArea,
    label: '',
    options: {
      minRow: isLoginUserAdmin ? 1 : 5,
      maxRow: isLoginUserAdmin ? 5 : 10,
      placeholder: i18n.t('documentHub.note.inputPlaceHolder'),
      characterLimit: isLoginUserAdmin ? undefined : DOCUMENT_NOTE_MAX_LENGTH,
      autoFocus: true,
      width: '100%',
      InputProps: {
        endAdornment: (
          <FormationsGhostButton
            rounded
            type="submit"
            style={isLoginUserAdmin ? { float: 'right' } : {}}
          >
            <SendIcon />
          </FormationsGhostButton>
        ),
      },
    },
  };

  const formInstance = useForm({
    defaultValues: {
      documentNote: '',
      mode: 'onChange',
      reValidateMode: 'onChange',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      isLoginUserAdmin ? documentNoteAdminSchema : documentNoteSchema,
    ),
  });

  useEffect(() => {
    if (notes && notes.length > 0) {
      executeScroll();
    }
  }, [notes, executeScroll]);

  useEffect(() => {
    if (documentId) {
      readNote();
    }
  }, [documentId]);

  const { handleSubmit, reset } = formInstance;

  const handleSubmitClick = async (data: { documentNote: string }) => {
    try {
      await createDocumentNote({
        note: data.documentNote,
        accountId: documentAccountId,
        documentId,
      });
      reset({
        documentNote: '',
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        showErrorToast(e.message);
      } else {
        showErrorToast('An error occurred while creating note.');
      }
    }
  };

  return (
    <>
      <Grid container sx={{ p: 3 }} flexDirection="column">
        <Typography variant="body2B" sx={{ mb: 3 }}>
          Notes
        </Typography>
        <Typography variant="body3">
          {isLoginUserAdmin
            ? `Your conversation with ${account?.ownerName ?? 'customer'}.`
            : 'Your conversation with Formations. You can add notes about the item, ask questions, or make requests.'}
        </Typography>
      </Grid>
      <Grid
        item
        data-testid="document-notes-container"
        key="documentNote"
        xs={12}
        sx={{ my: 0, maxHeight: 300, overflow: 'hidden', overflowY: 'auto' }}
      >
        {notes?.map((n, i) => (
          <Box key={n.id} maxWidth={408}>
            <DocumentNoteItem note={n} documentAccountId={documentAccountId} />
            {i < notes.length - 1 && <Divider sx={{ my: 1 }} />}
          </Box>
        ))}
        <div ref={noteContainerBottomRef} />
      </Grid>
      {notes && <Divider />}
      <form noValidate onSubmit={handleSubmit(handleSubmitClick)}>
        <DialogTitle sx={{ px: 3, py: 2 }}>New Note</DialogTitle>
        <DialogContent sx={{ px: 3, py: 0, width: 360 }}>
          <Grid item key="documentNote" xs={12} sx={{ my: 0 }}>
            {renderField(documentNote, formInstance)}
          </Grid>
        </DialogContent>
      </form>
    </>
  );
};
